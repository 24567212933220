import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme/index.ts';
import { ConfigurationProvider } from './hoc/configuration';
import SdkContext from './hoc/sdk-context';
import AppLoader from './hoc/app-loader';
import Authentication from './hoc/authentication';
import Authorization from './hoc/authorization';
import LayoutWrapper from './Layout/LayoutWrapper';
import { UserDataProvider } from './hoc/userdata-context';

const Main = ({ routes }) => {
  return (
    <HelmetProvider>
      <ChakraProvider theme={theme}>
        <ConfigurationProvider>
          <SdkContext>
            <>
              <AppLoader />
              <Authentication />
              <Authorization>
                <UserDataProvider>
                  <LayoutWrapper>
                    <RouterProvider router={routes} />
                  </LayoutWrapper>
                </UserDataProvider>
              </Authorization>
            </>
          </SdkContext>
        </ConfigurationProvider>
      </ChakraProvider>
    </HelmetProvider>
  );
};

export default Main;
