import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from '../slices/authSlice';
import appsReducer from '../slices/appsSlice';
import reportsReducer from '../slices/reportsSlice';

import apiService from '../services/apiService';
import { authenticationSlice } from '../slices/authentication.slice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authentication'],
  blacklist: [
    apiService.reducerPath,
  ],
};

const rootReducer = combineReducers({
  [apiService.reducerPath]: apiService.reducer,
  auth: authReducer,
  apps: appsReducer,
  reports: reportsReducer,
  authentication: authenticationSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }).concat(apiService.middleware),
});

export const persistor = persistStore(store);
