const AGREEMENT_MODAL_HEADER_TEXT = 'The Exchange End User License Agreement and Community User Guidelines';

const AGREEMENT_MODAL_SUB_HEADER_TEXT = 'Please take a moment to review The Exchange End User License Agreement and Community User Guidelines. You must accept the agreement to use The Exchange.';

const EULA_ACCEPT_CHECKBOX_TEXT = 'I agree to The Exchange End User License Agreement (Required to continue).';
const EULA_DECLINE_LABEL_TEXT = 'Or, close without agreeing. You will be logged out of The Exchange.';
const EULA_LOGOUT_ALERT_TITLE_TEXT = 'ARE YOU SURE?';
const EULA_LOGOUT_ALERT_BODY_TEXT = 'By not agreeing, You cannot use The Exchange.';
const EULA_LOGOUT_CONFIRMATION_BUTTON_LABEL = 'Yes, Log Me Out';

const GUIDELINES_ACCEPT_CHECKBOX_TEXT = 'I agree to Community User Guidelines.';
const GUIDELINES_DECLINE_LABEL_TEXT = 'Or, close without agreeing. You will not be able to interact with other members of the Community.';

const AGREEMENT_CONSTANT = {
  AGREEMENT_MODAL_HEADER_TEXT,
  AGREEMENT_MODAL_SUB_HEADER_TEXT,
  EULA_ACCEPT_CHECKBOX_TEXT,
  EULA_DECLINE_LABEL_TEXT,
  EULA_LOGOUT_ALERT_TITLE_TEXT,
  EULA_LOGOUT_ALERT_BODY_TEXT,
  EULA_LOGOUT_CONFIRMATION_BUTTON_LABEL,
  GUIDELINES_ACCEPT_CHECKBOX_TEXT,
  GUIDELINES_DECLINE_LABEL_TEXT,
};

export default AGREEMENT_CONSTANT;
