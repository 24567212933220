import React, { useContext, useEffect, useState } from 'react';
import { Flex, useDisclosure } from '@chakra-ui/react';
import {
  SessionInactiveModal,
  LoadingScreen,
  useAuthAutoRefresh,
  useAuthActions,
  useLoadingState,
  useIdleSession,
  TEEAuthDataContext,
} from '@texas-education-exchange/edx-portal-shared';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import DefaultLayoutWrapper from './DefaultLayoutWrapper';
import { setLoggedInUser } from '../slices/authSlice';
import {
  useLoginMutation,
  useLogoutMutation,
} from '../services/apis/usersAPI';
import SiteTour from './SiteTour';
import LoginUnauthorized from '../pages/LoginUnauthorized';
import { setToken } from '../slices/authentication.slice';
import { useUserData } from '../hoc/userdata-context';
import EULAModal from '../components/Modals/EULAModal';

const LayoutWrapper = () => {
  useAuthAutoRefresh();
  const { auth, edxAppConfig } = useContext(TEEAuthDataContext);
  const { handleLogIn, handleLogOut } = useAuthActions();
  const dispatch = useDispatch();
  const { loadingState, stateMessage } = useLoadingState();
  const { showInactiveModal, onCloseInactiveModal } = useIdleSession({ timeout: 10000 * 60 });
  const [isClosingSession, setIsClosingSession] = useState(false);

  const [loginUser] = useLoginMutation();
  const [logoutUser] = useLogoutMutation();

  const location = useLocation();
  const currentLocation = `${location.pathname}${location.search}${location.hash}`;

  const [searchParams] = useSearchParams();
  const refreshTour = searchParams.get('refreshTour') ?? 1;

  const onLogin = async () => {
    await handleLogIn();
    await loginUser();
  };

  const onLogout = async () => {
    await handleLogOut();
    await logoutUser();
    dispatch(setLoggedInUser({}));
    setIsClosingSession(true);
  };

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  // The only purpose of this call is to pass a me API call with a login header.

  console.log('loggedInUser:', loggedInUser);
  console.log('auth:', auth);

  const {
    error: loginError,
    isError: isLoginError,
    isSuccess: isLoginSuccess,
    userData,
    isLoading: isLoginLoading,
  } = useUserData();

  console.log('userData in LW:', userData);

  useEffect(() => {
    if (location.pathname !== '/callback') {
      localStorage.setItem('currentLocation', currentLocation);
    }
  }, [location.pathname, currentLocation]);

  useEffect(() => {
    console.log('auth.isAuthenticated', auth.isAuthenticated);
    console.log('auth.user', auth.user);

    if (auth.isAuthenticated && auth.user?.access_token) {
      dispatch(setToken(auth.user.access_token));
      dispatch(setLoggedInUser(auth.user));
    }
  }, [auth.isAuthenticated, auth.user, dispatch]);

  console.log('Loading state:', loadingState);
  console.log('isLoginSuccess:', isLoginSuccess);

  const isEULAAccepted = userData?.data?.latest_agreements?.eula?.status === 'accepted';
  const { isOpen, onOpen, onClose } = useDisclosure({ isOpen: !isEULAAccepted });

  return (
    <Flex id="LayoutWrapper">
      {(isLoginLoading) && (
        <LoadingScreen state={stateMessage} delay={1} loading={loadingState !== 'finished'} />
      )}
      <Helmet>
        <title>
          The Exchange |
          {' '}
          {stateMessage}
        </title>
      </Helmet>
      {isLoginError && loginError.status === 401 ? (
        <LoginUnauthorized />
      ) : (

        <>
          {auth.isAuthenticated && userData && !isEULAAccepted ? (
            <EULAModal
              isOpen={isOpen}
              onOpen={onOpen}
              onClose={onClose}
            />
          ) : null}
          <SessionInactiveModal
            show={showInactiveModal}
            isClosingSession={isClosingSession}
            onLogout={onLogout}
            onClose={onCloseInactiveModal}
          />
          <DefaultLayoutWrapper
            auth={auth}
            edxAppConfig={edxAppConfig}
            notificationBarMessage="Welcome to The Exchange"
            notificationId="community-announcement-v1"
            isClosingSession={isClosingSession}
            onLogin={onLogin}
            onLogout={onLogout}
            userData={userData}
          />
          {/* The key is a hack to force Joyride to restart when the refresh in the query string
            changes. The refreshTour parameter will be a random number. -JJ
        */}
          <SiteTour key={refreshTour} />
        </>
      )}
    </Flex>
  );
};

export default LayoutWrapper;
