import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import { validateToken } from '../slices/authentication.slice';
import { useGetUserQuery } from '../services/apis/usersAPI';

const UserDataContext = React.createContext();

export const useUserData = () => {
  return useContext(UserDataContext);
};

export const UserDataProvider = ({ children }) => {
  const { auth } = useContext(TEEAuthDataContext);

  const userAlreadyInRedux = useSelector(
    (state) => state.auth?.loggedInUser,
  );

  const {
    error,
    isError,
    isSuccess,
    isLoading,
    isFetching,
    data: userData,
    refetch,
  } = useGetUserQuery({
    login: userAlreadyInRedux?.profile?.sid !== auth?.user?.profile?.sid,
  }, {
    skip: !validateToken(),
  });

  const value = useMemo(() => ({
    userData,
    isLoading,
    isFetching,
    isError,
    error,
    isSuccess,
    refetch,
  }), [userData, isLoading, isFetching, isError, error, isSuccess, refetch]);

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};
