import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Box,
  Flex,
  useTheme,
  VStack,
  Heading,
  Button,
  Spacer,
  Select,
  Checkbox,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { TEEAuthDataContext, UserProfileContext } from '@texas-education-exchange/edx-portal-shared';
import { useLocation } from 'react-router-dom';
import SectionFooter from '../components/NavMenu/SectionFooter';
import MomentDatePicker from '../components/ElementaryComponents/MomentDatePicker';
import {
  useFetchAnalyticsReportsQuery,
  useCreateAnalyticsReportMutation,
} from '../services/apis/reportsAPI';
import { sanitizeData } from '../utils/helpers';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';
import UserSelectModal from '../components/Modals/UserSelectModal';
import { useGetTenantsQuery } from '../services/apis/usersAPI';
import DataTable from '../components/Table/DataTable';
import REPORT_TYPES from '../components/Analytics/ReportType';
import { useUserData } from '../hoc/userdata-context';

const ModerationReports = ({ variant = 'solid', customBtnStyle = {} }) => {
  const theme = useTheme();
  const location = useLocation();
  const { auth, edxAppConfig } = useContext(TEEAuthDataContext);
  const { userData: { access_sidebar } } = useUserData();
  const { userProfile: { selectedTenant } } = useContext(UserProfileContext);
  const {
    moderator_report: accessToModeratorReport,
    infraction_report: accessToInfractionReport,
  } = access_sidebar;

  const [viewReportType, setViewReportType] = useState('All');
  const [showMyReports, setShowMyReports] = useState(false);
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const today = moment().format('MM/DD/YYYY');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const minDateForEndDate = startDate
    ? startDate.format('MM/DD/YYYY')
    : '01/01/2023';
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedTab, setSelectedTab] = useState(
    location?.state?.type === REPORT_TYPES.MODERATOR || !accessToInfractionReport
      ? 1 : 0,
  );
  const indexedTabs = [REPORT_TYPES.INFRACTION, REPORT_TYPES.MODERATOR];

  const [sorting, setSorting] = useState([{
    id: 'created_at',
    desc: true,
  }]);

  const userRole = useMemo(() => { return auth?.user?.profile?.role?.filter((role) => role.startsWith('Community.')); }, [auth]);

  const userAdminAccessLevel = useMemo(() => {
    const role = userRole?.[0]?.split('.')?.[1];
    let accessLevel = null;

    if (role === 'LeaAdmin') {
      accessLevel = 'LEA';
    }
    if (role === 'EscAdmin') {
      accessLevel = 'ESC';
    }
    if (role === 'ExchangeAdmin') {
      accessLevel = 'EXCHANGE_ADMIN';
    }
    return accessLevel;
  }, [userRole]);

  const {
    data: tenantValues,
  } = useGetTenantsQuery(undefined, {
    enabled: userAdminAccessLevel === 'EXCHANGE_ADMIN',
  });

  const tenantData = tenantValues?.data ?? [];

  const userAdminReportAccess = {
    EXCHANGE_ADMIN: ['All', 'Region', 'LEA'],
    ESC: ['Region', 'LEA'],
    LEA: ['LEA'],
  };

  const [reportType, setReportType] = useState(
    selectedTab === 0 ? 'Aggregated' : userAdminReportAccess[userAdminAccessLevel][0],
  );

  const reportSubType = indexedTabs[selectedTab];
  const [selectedUserId, setSelectedUserId] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  const [moderatorSubType, setModeratorSubType] = useState(
    selectedTenant?.tenantId,
  );

  const handleTabChange = (index) => {
    setSelectedTab(index);
    setReportType(index === 0 ? 'Aggregated' : userAdminReportAccess[userAdminAccessLevel][0]);
    setCurrentPage(1);
  };

  const [createReport, { isLoading, isError, error }] = useCreateAnalyticsReportMutation();

  const handleUserSelect = (userId, userName) => {
    setSelectedUserId(userId);
    setSelectedUserName(userName);
  };

  const handleClearUser = () => {
    setSelectedUserId(null);
    setSelectedUserName(null);
  };

  const userSelect = reportType === 'Individual' && reportSubType === 'infraction';

  const handleCreateReport = async () => {
    let newReport = {
      author_id: sanitizeData(auth.user.profile.sub),
      start_date: startDate,
      end_date: endDate,
      report_type: reportType,
      report_sub_type: reportSubType,
    };
    if (userSelect) {
      newReport = {
        ...newReport,
        report_meta: {
          user_id: selectedUserId,
        },
      };
    }

    const reportMeta = newReport.report_meta;

    let subgroupId = moderatorSubType;
    if (reportType === 'Region') {
      subgroupId = tenantData.find(
        (district) => district.edgraph_tenant_id === moderatorSubType,
      ).region;
    }

    if (reportSubType === 'moderator') {
      newReport = {
        ...newReport,
        report_meta: {
          ...reportMeta,
          report_timezone: 'America/Chicago',
          subgroup_id: subgroupId,
        },
      };
    }

    createReport(newReport);
  };

  const validateForm = useCallback(() => {
    const allDateInputFilled = startDate && endDate;

    const momentStartDate = moment(startDate);
    const momentEndDate = moment(endDate);
    const isStartDateInPast = momentStartDate.diff(moment(), 'minutes') < 0;
    const isEndDateAfterStartAndInPast = momentEndDate.diff(momentStartDate, 'minutes') > 0
      && momentEndDate.diff(moment(), 'minutes') < 0;

    if (selectedTab === 1
        && startDate && startDate.isBefore(edxAppConfig.app.moderatorReportStartDate)) {
      setIsFormValid(false);
    } else if (
      isStartDateInPast
      && isEndDateAfterStartAndInPast
      && allDateInputFilled
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [startDate, endDate, edxAppConfig, selectedTab]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleSortingChange = useCallback((newSorting) => {
    setSorting(newSorting);
  }, []);

  const reportsQueryObj = {
    page_number: currentPage,
    page_size: selectedShowNumber,
    report_type:
    (reportSubType === 'infraction'
      && (viewReportType === 'Aggregated' || viewReportType === 'Individual'))
      ? viewReportType : undefined,
    report_sub_type: reportSubType,
    author_id: showMyReports ? loggedInUserId : undefined,
    sort_by: sorting[0].id,
    sort_order: sorting[0].desc ? 'desc' : 'asc',
  };

  const {
    data: reportsData,
    isSuccess: isReportsDataSuccess,
    isFetching: isFetchingReportsData,
    isError: isReportsDataError,
    error: reportsDataError,
  } = useFetchAnalyticsReportsQuery(reportsQueryObj);

  const analyticsTitleStyle = {
    ...theme.styles.global.h1,
    color: `${theme.colors.blue[800]}`,
    display: 'inline-block',
  };

  const canCreate = reportsData?.permissions?.report?.can_create;

  const handleCheckboxChange = (checked) => {
    setShowMyReports(checked);
    setCurrentPage(1);
  };

  const handleSelectChange = (e) => {
    if (selectedTab === 0) {
      setViewReportType(e.target.value);
    } else {
      setViewReportType(undefined);
    }
    setCurrentPage(1);
  };

  const generateModeratorSubTypeOptions = () => {
    return tenantData.map((district) => (
      <option
        key={district.edgraph_tenant_id}
        value={district.edgraph_tenant_id}
        aria-label={district.name}
      >
        {district.name}
      </option>
    ));
  };

  const generateModeratorSubTypeDropdown = (adminRole, selectedReport) => {
    const showLEAOptions = adminRole === 'LEA' && selectedReport === 'LEA';

    if (adminRole === 'ESC') {
      if (selectedReport === 'Region') return <div />;
    }

    const options = showLEAOptions ? (
      <option
        value={selectedTenant.organizationName}
        aria-label={selectedTenant.organizationName}
      >
        {selectedTenant.organizationName}
      </option>
    ) : generateModeratorSubTypeOptions();

    return (
      <Select
        id="moderatorSubType"
        aria-label="Select sub group type"
        onChange={(e) => setModeratorSubType(e.target.value)}
        height="2.875rem"
        width="20rem"
        mt={2}
        defaultValue={moderatorSubType}
        isDisabled={showLEAOptions}
      >
        {options}
      </Select>
    );
  };

  return (
    <Box w="full">
      <Flex justifyContent="space-between" alignItems="center" py={4}>
        <Text style={analyticsTitleStyle}>Moderation Reports</Text>
      </Flex>
      <Box>
        <Tabs onChange={handleTabChange} index={selectedTab}>
          <TabList>
            <Tab
              _selected={{
                fontWeight: 'bold',
                color: 'blue.600',
                borderBottom: '0.125rem solid',
              }}
              style={{ width: '8.125rem' }}
              position="relative"
              hidden={!accessToInfractionReport}
            >
              Infractions
            </Tab>
            <Tab
              _selected={{
                fontWeight: 'bold',
                color: 'blue.600',
                borderBottom: '0.125rem solid',
              }}
              style={{ width: '8.125rem' }}
              position="relative"
              hidden={!accessToModeratorReport}
            >
              Moderators
            </Tab>
          </TabList>
          <VStack
            mt={2}
            style={{
              ...theme.styles.global.container,
              minHeight: '4rem',
              padding: '1rem',
              alignSelf: 'stretch',
              alignItems: 'flex-start',
            }}
            spacing={4}
          >
            <Flex
              width="full"
              height="5.5rem"
              justifyContent="space-between"
              alignItems="center"
              mr="4"
              gap="0.5"
            >
              <Flex alignItems="center" mr={4}>
                <MomentDatePicker
                  label="From"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  minDate={selectedTab === 1
                    ? edxAppConfig.app.moderatorReportStartDate : undefined}
                  maxDate={today}
                  style={{
                    '& input': {
                      height: '0.75rem',
                      width: '6.563rem',
                    },
                  }}
                />
                <MomentDatePicker
                  label="To"
                  value={endDate}
                  onChange={(date) => setEndDate(date)}
                  minDate={minDateForEndDate}
                  maxDate={today}
                  style={{
                    '& input': {
                      height: '0.75rem',
                      width: '6.563rem',
                    },
                  }}
                />

                {/* Report Type */}
                <FormControl>
                  <FormLabel
                    htmlFor="reportType"
                    style={{
                      fontFamily: 'Open Sans',
                      transitionProperty: 'background-color',
                      lineHeight: '22px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '16px',
                    }}
                  >
                    {selectedTab === 0 ? 'Infractions' : 'Moderator Group'}
                  </FormLabel>
                  <Select
                    id="reportType"
                    aria-label="Select create report type"
                    onChange={(e) => setReportType(e.target.value)}
                    height="2.875rem"
                    width={selectedTab === 0 ? '14rem' : '16.5rem'}
                    mt={2}
                    value={reportType}
                  >
                    {selectedTab === 0 ? (
                      <>
                        <option
                          value="Aggregated"
                          aria-label="Aggregate Infractions"
                        >
                          Aggregate Infractions
                        </option>
                        <option
                          value="Individual"
                          aria-label="Individual Infractions"
                        >
                          Individual Infractions
                        </option>
                      </>
                    ) : (
                      <>
                        <option
                          value="All"
                          aria-label="All Community Moderators"
                          hidden={!userAdminReportAccess[userAdminAccessLevel].includes('All')}
                        >
                          All Community Moderators
                        </option>
                        <option
                          value="Region"
                          aria-label="A Region"
                          hidden
                        >
                          A Region
                        </option>
                        <option
                          value="LEA"
                          aria-label="A District/Charter School"
                          hidden={!userAdminReportAccess[userAdminAccessLevel].includes('LEA')}
                        >
                          A District/Charter School
                        </option>
                      </>
                    )}
                  </Select>
                </FormControl>

                {/* Sub Group */}
                <FormControl ml="1rem" hidden={reportType === 'All'}>
                  <FormLabel
                    htmlFor="moderatorSubType"
                    style={{
                      fontFamily: 'Open Sans',
                      transitionProperty: 'background-color',
                      lineHeight: '22px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      fontSize: '16px',
                    }}
                  >
                    {selectedTab === 0
                    || (reportType === 'Region' && userAdminAccessLevel === 'ESC')
                      ? ' '
                      : reportType === 'LEA'
                        ? 'District/Charter School'
                        : 'Region'}
                  </FormLabel>
                  {selectedTab === 0 ? (
                    <div />
                  ) : (
                    generateModeratorSubTypeDropdown(userAdminAccessLevel, reportType)
                  )}
                </FormControl>
              </Flex>
              {userSelect && !selectedUserId && (
                <UserSelectModal onUserSelect={handleUserSelect} />
              )}
              {userSelect && selectedUserId && (
                <Flex align="center">
                  <Text
                    style={{ ...theme.styles.global.h4 }}
                    minWidth="5.625rem"
                    mt={7}
                    mr={4}
                  >
                    {selectedUserName}
                  </Text>
                  <Button
                    onClick={handleClearUser}
                    height="2.875rem"
                    width="7.5rem"
                    colorScheme="blue"
                    variant="outline"
                    mt={7}
                    mr={4}
                    style={{
                      ...theme.styles.global.button,
                      fontWeight: '600',
                      backgroundColor: 'white',
                    }}
                    size="md"
                  >
                    Clear
                  </Button>
                </Flex>
              )}
              <Spacer />
              {canCreate && (
                <Button
                  colorScheme="blue"
                  bg="blue.500"
                  variant={variant}
                  style={{
                    ...theme.styles.global.button,
                    fontWeight: '600',
                    ...customBtnStyle,
                  }}
                  size="lg"
                  minWidth="12.5rem"
                  height="3.5rem"
                  onClick={handleCreateReport}
                  isDisabled={!isFormValid || (userSelect && !selectedUserId)}
                >
                  {selectedTab === 1
                    ? 'Create Report'
                    : reportType === 'Aggregated'
                      ? 'Create Aggregate Report'
                      : 'Create Individual Report'}
                </Button>
              )}
            </Flex>
            {isLoading && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert
                  status="loading"
                  alert_title="Creating Report..."
                />
              </Box>
            )}
            {isError && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={error} />
              </Box>
            )}
          </VStack>
          <Heading
            as="h2"
            size="lg"
            mt={8}
            color="blue.800"
            style={{
              ...theme.styles.global.h3,
            }}
          >
            Reports
          </Heading>
          <Flex
            mt={2}
            style={{
              ...theme.styles.global.container,
              minHeight: '4rem',
              padding: '1rem',
              alignSelf: 'stretch',
              alignItems: 'flex-start',
            }}
            spacing={4}
            direction="column"
          >
            <HStack
              width="full"
              borderBottom="1px solid #CED8DD"
              alignItems="center"
              pb={4}
            >
              {selectedTab === 0 && (
                <Select
                  aria-label="Select generated report type"
                  onChange={handleSelectChange}
                  value={viewReportType}
                  height="2.875rem"
                  width="13.75rem"
                  mr={4}
                >
                  <option value="All" aria-label="All">
                    All
                  </option>
                  <option value="Aggregated" aria-label="Aggregate">
                    Aggregate
                  </option>
                  <option value="Individual" aria-label="Individual">
                    Individual
                  </option>
                </Select>
              )}
              <Checkbox
                size="lg"
                label="test"
                isChecked={showMyReports}
                onChange={(e) => handleCheckboxChange(e.target.checked)}
                style={{
                  ...theme.styles.global.body,
                }}
              >
                Show Only Reports I Created
              </Checkbox>
            </HStack>
            <TabPanels>
              {isReportsDataError && (
                <Box style={{ padding: '1rem 1.5rem' }}>
                  <StatusAlert status="error" error={reportsDataError} />
                </Box>
              )}
              {isFetchingReportsData && (
                <Box style={{ padding: '1rem 1.5rem' }}>
                  <StatusAlert
                    status="loading"
                    alert_title="Fetching Reports..."
                  />
                </Box>
              )}
              <TabPanel>
                {isReportsDataSuccess
                && (
                <DataTable
                  tableData={reportsData?.data}
                  sorting={sorting}
                  onSortingChange={handleSortingChange}
                  isSortOrderDesc={sorting[0].desc}
                  selectedTab={selectedTab}
                />
                )}
              </TabPanel>

              <TabPanel>
                {isReportsDataSuccess
                 && (
                 <DataTable
                   tableData={reportsData?.data}
                   sorting={sorting}
                   onSortingChange={handleSortingChange}
                   isSortOrderDesc={sorting[0].desc}
                   selectedTab={selectedTab}
                 />
                 )}
              </TabPanel>
            </TabPanels>
            <SectionFooter
              selectedShowNumber={selectedShowNumber}
              setSelectedShowNumber={setSelectedShowNumber}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalCount={reportsData?.meta.total_pages}
            />
          </Flex>
        </Tabs>
      </Box>
    </Box>
  );
};

export default ModerationReports;
