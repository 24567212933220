/* eslint-disable consistent-return */
import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useTheme,
  Box,
  Textarea,
  Text,
  Flex,
  VStack,
  FormHelperText,
  Select,
} from '@chakra-ui/react';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import {
  useCreateEventMutation,
  useUpdateEventByIdMutation,
} from '../../services/apis/eventsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { sanitizeData, removeHTMLAndSpaces } from '../../utils/helpers';
import MomentDatePicker from '../ElementaryComponents/MomentDatePicker';
import MomentTimePicker from '../ElementaryComponents/MomentTimePicker';
import TagsInput from '../ElementaryComponents/TagsInput';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';
import { useGetZipCodeCoordinatesQuery } from '../../services/apis/osmAPI';

const CreateEventModal = ({
  buttonLabel,
  variant = 'solid',
  customBtnStyle = {},
  size = 'md',
  isEditing,
  eventData,
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { auth } = useContext(TEEAuthDataContext);
  const today = moment();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tags, setTags] = useState([]);
  const [numberOfSaveAttempted, setNumberOfSaveAttempted] = useState(0);
  const [eventTitle, setEventTitle] = useState(eventData?.title || '');
  const [eventDescription, setEventDescription] = useState(eventData?.description || '');
  const [registrationLink, setRegistrationLink] = useState(eventData?.registration_link || '');
  const [contactPerson, setContactPerson] = useState(eventData?.contact_person || '');
  const [eventHost, setEventHost] = useState(eventData?.event_host || '');
  const [startTime, setStartTime] = useState(
    eventData?.start_date ? moment(eventData?.start_date) : null,
  );
  const [startDate, setStartDate] = useState(
    eventData?.start_date ? moment(eventData?.start_date) : null,
  );
  const [endTime, setEndTime] = useState(
    eventData?.end_date ? moment(eventData?.end_date) : null,
  );
  const [endDate, setEndDate] = useState(eventData?.end_date ? moment(eventData?.end_date) : null);
  const [eventAddress, setEventAddress] = useState(eventData?.location?.address || '');
  const [eventCity, setEventCity] = useState(eventData?.location?.city || '');
  const [eventState, setEventState] = useState(eventData?.location?.state || '');
  const [eventZip, setEventZip] = useState(eventData?.location?.zip_code || '');
  const [addressCoordinates, setAddressCoordinates] = useState({
    lat: null,
    lon: null,
    isValidZip: false,
  });
  const [isInvalidURL, setIsInvalidURL] = useState(false);
  const [locationType, setLocationType] = useState(eventData?.type || '');
  const allLocationsType = ['On site', 'Hybrid', 'Virtual'];

  const userTimezone = moment.tz.guess();
  const TZAbbreviated = moment.tz(userTimezone).zoneAbbr();

  const {
    data: coordinates,
  } = useGetZipCodeCoordinatesQuery(eventZip, {
    skip: !eventZip,
  });

  useEffect(() => {
    setAddressCoordinates(coordinates);
  }, [coordinates]);

  const [
    createEvent,
    { isLoading, isError: isCreatingEventError, error: creatingEventError },
  ] = useCreateEventMutation();

  const isValidURL = (str) => {
    const pattern = new RegExp(
      '^(http(s)?:\\/\\/)?'
        + '([-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.)'
        + '[a-z]{2,6}\\b'
        + '([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$',
      'i',
    );
    return pattern.test(str);
  };

  const isAddressDetailsValid = useMemo(() => {
    return eventAddress.length > 0 && eventAddress?.length <= 64
      && eventCity.length > 0 && eventCity.length <= 64
      && eventState.length > 0 && eventState.length <= 64
      && eventZip.length > 0 && eventZip.length <= 64;
  }, [
    eventAddress,
    eventCity,
    eventState,
    eventZip,
  ]);

  const isEventLocationTypeValid = useMemo(() => {
    const isRegistrationLinkValid = registrationLink?.length > 0;
    switch (locationType) {
      case 'On site':
        return isAddressDetailsValid;
      case 'Hybrid':
        return isAddressDetailsValid && isRegistrationLinkValid;
      case 'Virtual':
        return isRegistrationLinkValid;
      default:
        return false;
    }
  }, [
    isAddressDetailsValid,
    registrationLink,
    locationType,
  ]);

  const isEventTitleValid = useMemo(() => {
    return removeHTMLAndSpaces(eventTitle)
      && eventTitle.length <= 64
      && eventTitle.length > 0;
  }, [eventTitle]);

  const isEventDescriptionValid = useMemo(() => {
    return removeHTMLAndSpaces(eventDescription)
      && eventDescription.length <= 560
      && eventDescription.length > 0;
  }, [eventDescription]);

  const isContactPersonValid = useMemo(() => {
    return removeHTMLAndSpaces(contactPerson)
      && contactPerson.length <= 64
      && contactPerson.length > 0;
  }, [contactPerson]);

  const isEventStartAndEndValid = useMemo(() => {
    const momentStartTime = moment(startTime);
    const correctEventStart = moment(startDate)
      .hour(momentStartTime.hours())
      .minute(momentStartTime.minutes());

    const momentEndTime = moment(endTime);
    const correctEventEnd = moment(endDate)
      .hour(momentEndTime.hours())
      .minute(momentEndTime.minutes());

    const isStartDateInFuture = correctEventStart.diff(moment(), 'minutes') > 0;
    const isEndDateAfterStartDate = correctEventEnd.diff(correctEventStart, 'minutes') >= 15;
    return isStartDateInFuture && isEndDateAfterStartDate;
  }, [startTime, startDate, endTime, endDate]);

  const isFormValid = useMemo(() => {
    return isEventTitleValid
    && isEventDescriptionValid
    && isEventLocationTypeValid
    && isContactPersonValid
    && isEventStartAndEndValid
    && (numberOfSaveAttempted === 0 || (numberOfSaveAttempted > 0 && isValidURL(registrationLink)));
  }, [
    isEventTitleValid,
    isEventDescriptionValid,
    isEventLocationTypeValid,
    isContactPersonValid,
    isEventStartAndEndValid,
    numberOfSaveAttempted,
    registrationLink,
  ]);

  const [
    editEvent,
    {
      isLoading: isEditEventLoading,
      isError: isEditEventError,
      error: editEventError,
    },
  ] = useUpdateEventByIdMutation();

  const modalUpdate = () => {
    setEventTitle('');
    setEventDescription('');
    setContactPerson('');
    setEventHost('');
    setRegistrationLink('');
    setStartDate(today);
    setStartTime(null);
    setEndDate(today);
    setEndTime(null);
    setTags([]);
    setEventAddress('');
    setEventCity('');
    setEventState('');
    setEventZip('');
    setLocationType('');
    setIsSubmitting(false);
    setIsInvalidURL(false);
    setNumberOfSaveAttempted(0);
  };

  const onClose = () => {
    modalUpdate();
    onModalClose();
  };

  const handleCreateEvent = async () => {
    if (isSubmitting) {
      return;
    }
    setNumberOfSaveAttempted((prev) => prev + 1);

    if (!isValidURL(registrationLink)) {
      setIsInvalidURL(true);
      return;
    }
    setIsSubmitting(true);
    const selectedStartTime = startTime || eventData?.start_time;
    const selectedEndTime = endTime || eventData?.end_time;
    const selectedStartDate = startDate || moment(eventData?.start_date).format('MM/DD/YYYY');
    const selectedEndDate = endDate || moment(eventData?.end_date).format('MM/DD/YYYY');

    const combinedStartDate = moment(selectedStartDate)
      .hour(moment(selectedStartTime).hour())
      .minute(moment(selectedStartTime).minute())
      .second(moment(selectedStartTime).second())
      .toISOString();
    const combinedEndDate = moment(selectedEndDate)
      .hour(moment(selectedEndTime).hour())
      .minute(moment(selectedEndTime).minute())
      .second(moment(selectedEndTime).second())
      .toISOString();

    if (registrationLink && !isValidURL(registrationLink)) {
      setIsInvalidURL(true);
      setIsSubmitting(false);
      return;
    }
    setIsInvalidURL(false);

    const now = moment();
    if (moment(combinedStartDate).isBefore(now)) {
      <StatusAlert
        status="error"
        error="Event Start Time and Date must be in the future."
      />;
      return;
    }

    if (moment(combinedEndDate).isBefore(combinedStartDate)) {
      <StatusAlert
        status="error"
        error="Event End Time and Date must be after the start time and date."
      />;
      return;
    }

    try {
      const newEvent = {
        title: sanitizeData(eventTitle),
        description: sanitizeData(eventDescription),
        tags: sanitizeData(tags, 'arr_of_str'),
        registration_link: sanitizeData(registrationLink),
        contact_person: sanitizeData(contactPerson),
        event_host: sanitizeData(eventHost),
        start_date: combinedStartDate,
        end_date: combinedEndDate,
        type: locationType,
      };

      if (['On site', 'Hybrid'].includes(locationType)) {
        const { lon, lat, isValidZip } = addressCoordinates;

        newEvent.location = {
          address: sanitizeData(eventAddress),
          city: sanitizeData(eventCity),
          state: sanitizeData(eventState),
          zip_code: sanitizeData(eventZip),
        };
        if (isValidZip) {
          newEvent.location.geo_location = [lon, lat];
        }
      }

      if (!isEditing) {
        newEvent.author_id = sanitizeData(auth.user?.profile.sub);
      }
      if (isEditing) {
        await editEvent({ event_id: eventData._id, eventData: newEvent });
      } else {
        await createEvent(newEvent);
      }
      onClose();
    } catch (err) {
      console.log('Error creating or updating event:', err);
    }
    setIsSubmitting(false);
  };

  return (
    <Box>
      <Button
        id="create-event"
        colorScheme="blue"
        bg={variant === 'solid' ? 'blue.500' : 'white'}
        variant={variant}
        onClick={onOpen}
        style={{
          ...theme.styles.global.button,
          fontWeight: '600',
          ...customBtnStyle,
        }}
        size={size}
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent borderTop="8px solid" borderColor="blue.600">
          <ModalHeader style={{ ...theme.styles.global.h3 }}>
            {isEditing ? 'Edit Event' : 'Create Event'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl id="group_modal" isRequired mb="1rem">
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Event Name
                </FormLabel>
                <Input
                  value={parse(eventTitle)}
                  onChange={(e) => setEventTitle(e.target.value)}
                />
                <MaxCharExceedError
                  message="Event name"
                  charCount={eventTitle?.length}
                />
              </FormControl>
              <FormControl id="description" mt={4} isRequired>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Event Description
                </FormLabel>
                <Textarea
                  placeholder="Description"
                  resize="vertical"
                  minH="96px"
                  value={parse(eventDescription)}
                  onChange={(e) => setEventDescription(e.target.value)}
                />
                <MaxCharExceedError
                  message="Event description"
                  charCount={eventDescription?.length}
                  max_char_limit="560"
                />
              </FormControl>
              <Flex gap={4}>
                <FormControl
                  id="registration_link"
                  mt={4}
                  style={{
                    width: '200px',
                  }}
                  isRequired
                >
                  <FormLabel
                    style={{
                      ...theme.styles.global.body,
                      fontSize: '14px',
                      margin: 0,
                      marginBottom: '4px',
                    }}
                    isRequired
                  >
                    Location Type
                  </FormLabel>
                  <Select
                    height="40px"
                    placeholder="Select type "
                    aria-label="Select the location type for this event."
                    onChange={(e) => setLocationType(e.target.value)}
                    sx={{ padding: '0 12px', borderRadius: '4px' }}
                    value={locationType}
                  >
                    {allLocationsType.map((type) => {
                      return (
                        <option value={type} key={type}>
                          {type}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  id="registration_link"
                  mt={4}
                  isRequired={['Hybrid', 'Virtual'].includes(locationType)}
                >
                  <FormLabel
                    style={{
                      ...theme.styles.global.body,
                      fontSize: '14px',
                      margin: 0,
                      marginBottom: '4px',
                    }}
                  >
                    Registration or Event Link
                  </FormLabel>
                  <Input
                    value={registrationLink}
                    placeholder="https://"
                    onChange={(e) => setRegistrationLink(e.target.value)}
                  />
                  <FormHelperText variant="details">
                    Please use either HTTP or HTTPS.
                  </FormHelperText>
                </FormControl>
              </Flex>
              {['On site', 'Hybrid'].includes(locationType) && (
                <>
                  <FormControl id="address" mb={2} isRequired>
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Address
                    </FormLabel>
                    <Input
                      value={eventAddress}
                      placeholder="The street address"
                      onChange={(e) => setEventAddress(e.target.value)}
                    />
                    <MaxCharExceedError
                      message="Address"
                      charCount={eventAddress?.length}
                    />
                  </FormControl>
                  <Flex direction="row">
                    <FormControl id="city" mr={4} w="50%" isRequired>
                      <FormLabel
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                          margin: 0,
                          marginBottom: '4px',
                        }}
                      >
                        City
                      </FormLabel>
                      <Input
                        value={parse(eventCity)}
                        onChange={(e) => setEventCity(e.target.value)}
                      />
                      <MaxCharExceedError
                        message="City"
                        charCount={eventCity?.length}
                      />
                    </FormControl>
                    <FormControl id="state" mr={4} w="20%" isRequired>
                      <FormLabel
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                          margin: 0,
                          marginBottom: '4px',
                        }}
                      >
                        State
                      </FormLabel>
                      <Input
                        value={parse(eventState)}
                        onChange={(e) => setEventState(e.target.value)}
                      />
                      <MaxCharExceedError
                        message="State"
                        charCount={eventState?.length}
                      />
                    </FormControl>
                    <FormControl id="zip_code" w="30%" isRequired>
                      <FormLabel
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                          margin: 0,
                          marginBottom: '4px',
                        }}
                      >
                        Zip Code
                      </FormLabel>
                      <Input
                        value={parse(eventZip)}
                        onChange={(e) => setEventZip(e.target.value)}
                      />
                      <MaxCharExceedError
                        message="Zip code"
                        charCount={eventZip?.length}
                      />
                    </FormControl>
                  </Flex>
                </>
              )}
              <FormControl id="contact_person" isRequired mt={4}>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Contact Person
                </FormLabel>
                <Input
                  value={parse(contactPerson)}
                  placeholder="Enter a name, email address, or contact info"
                  onChange={(e) => setContactPerson(e.target.value)}
                />
                <MaxCharExceedError
                  message="Contact person name"
                  charCount={contactPerson?.length}
                />
              </FormControl>
              <FormControl id="event_host" mt={4}>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Event Host/Sponsor
                </FormLabel>
                <Input
                  value={parse(eventHost)}
                  placeholder="May be an organization or person"
                  onChange={(e) => setEventHost(e.target.value)}
                />
                <MaxCharExceedError
                  message="Event host/sponsor "
                  charCount={eventHost?.length}
                />
              </FormControl>
              <Box mt={4}>
                <TagsInput tags={tags} setTags={setTags} label="Add Tags" />
              </Box>
              <FormControl id="start_time" isRequired mb="1rem" mt="1rem">
                <Flex
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  width="90%"
                >
                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event Start Date
                    </FormLabel>
                    <MomentDatePicker
                      value={startDate}
                      minDate={today}
                      onChange={(date) => setStartDate(date)}
                    />
                  </VStack>

                  <Flex flexDirection="column" justifyContent="flex-end">
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      height="45px"
                      pr="1rem"
                    >
                      <Text
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                        }}
                      >
                        at
                      </Text>
                    </Flex>
                  </Flex>

                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event Start Time (
                      {TZAbbreviated}
                      )
                    </FormLabel>
                    <MomentTimePicker
                      value={startTime}
                      minValue={
                        startDate && startDate.isSame(today, 'day')
                          ? today
                          : undefined
                      }
                      onChange={(time) => setStartTime(time)}
                    />
                  </VStack>
                </Flex>
              </FormControl>
              <FormControl id="end_time" isRequired mb="1rem">
                <Flex
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  width="90%"
                >
                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event End Date
                    </FormLabel>
                    <MomentDatePicker
                      value={endDate}
                      minDate={startDate || today}
                      onChange={(date) => setEndDate(date)}
                    />
                  </VStack>
                  <Flex flexDirection="column" justifyContent="flex-end">
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      height="45px"
                      pr="1rem"
                    >
                      <Text
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                        }}
                      >
                        at
                      </Text>
                    </Flex>
                  </Flex>
                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event End Time (
                      {TZAbbreviated}
                      )
                    </FormLabel>
                    <MomentTimePicker
                      value={endTime}
                      minValue={
                        startDate && endDate && startDate.isSame(endDate, 'day')
                          ? startTime?.clone().add(15, 'minutes')
                          : undefined
                      }
                      onChange={(time) => setEndTime(time)}
                    />
                  </VStack>
                </Flex>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter alignSelf="flex-start">
            <Button
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
              style={{ ...theme.styles.global.body }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              style={{ ...theme.styles.global.body }}
              onClick={() => handleCreateEvent()}
              isDisabled={!isFormValid || isSubmitting}
              _hover={{ opacity: !isFormValid ? 0.4 : 0.9 }}
            >
              Save
            </Button>
          </ModalFooter>
          {(isLoading || isEditEventLoading) && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="loading" alert_title="Creating Event..." />
            </Box>
          )}
          {isCreatingEventError && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="error" error={creatingEventError} />
            </Box>
          )}
          {isEditEventError && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="error" error={editEventError} />
            </Box>
          )}
          {isInvalidURL && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert
                status="error"
                alert_title="Invalid registration link. Link must include a valid domain."
              />
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateEventModal;
