import React from 'react';
import {
  VStack, useTheme, Box, Flex, Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import { formatStatusText, getRelativeTime, joinTruthyStrings } from '../../utils/helpers';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import ProfileCard from '../PopOver/ProfileCardPopOver';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';
import FlaggedItemTitle from '../ElementaryComponents/FlaggedItemTitle';

const Groups = ({ groupsData = [], groupsPermissions = {}, noDataMessage }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const can_view_hidden = groupsPermissions?.can_view_hidden;
  const displayedGroupsCount = groupsData?.length;
  const accessibilityKeyHandler = useAccessibleNavigation();

  return groupsData.length === 0 ? (
    <DataUnavailable display_message={noDataMessage} />
  ) : (
    <VStack
      style={{
        ...theme.styles.global.container,
      }}
      minHeight="4rem"
      padding="1rem"
      alignSelf="flex-end"
      spacing={2}
      alignItems="flex-start"
    >
      {groupsData?.map((groupData, index) => {
        const {
          title,
          _id,
          post_count,
          pin,
          tags,
          comment_count,
          latest_post = {},
          is_locked,
          is_hidden,
          user_locked,
          user_banned,
          visibility,
        } = groupData;

        const {
          author_body: latest_post_author_body,
          created_at: latest_post_created_at,
          title: latest_post_title,
        } = latest_post;

        let authorName = joinTruthyStrings({
          strings: [latest_post_author_body?.first_name, latest_post_author_body?.last_name],
          connectingString: ' ',
        });
        if (!authorName) authorName = latest_post_author_body?._id;

        const { status, formatedStatusText, showFlaggedItemTitle } = formatStatusText({
          can_view_hidden,
          includeStatusText: true,
          is_locked,
          is_hidden,
          user_locked,
          user_banned,
        });

        const groupCardStyle = index !== displayedGroupsCount - 1
          ? {
            borderBottom: '1px solid #CED8DD',
            marginBottom: '1rem',
            marginTop: 0,
          }
          : { marginTop: 0 };

        // TODO: add parent if there is app_id or course_id
        // Create Pin Body
        const createPinBodyObj = {
          child_object_id: _id,
          child_object_type: 'group',
        };
        const navigatePath = `/forum/${_id}`;

        return (
          <Box
            key={_id}
            role="link"
            tabIndex={0}
            onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath)}
            style={groupCardStyle}
            pb="1rem"
            width="100%"
            cursor="pointer"
            onClick={() => navigate(navigatePath)}
          >
            <Flex width="100%" alignItems="center">
              <Box>
                {showFlaggedItemTitle ? (
                  <FlaggedItemTitle
                    title={title}
                    status={status}
                    formatedStatusText={formatedStatusText}
                  />
                ) : (
                  <Text
                    style={{
                      ...theme.styles.global.h4,
                      display: 'inline-block',
                      fontWeight: 700,
                    }}
                    color="gray.800"
                  >
                    {parse(title)}
                  </Text>
                )}
              </Box>

              <Flex ml="auto" justify="flex-end" alignItems="center">
                <TogglePinIcon
                  is_pinned={pin}
                  create_pin_body_obj={createPinBodyObj}
                  pin_id={groupData?.pin_id}
                />
              </Flex>
            </Flex>

            <Flex alignItems="center" width="100%" gap="0.75rem" mb="0.25rem">
              {visibility !== 'public' ? (
                <Text
                  background={theme.colors.blue['100']}
                  color="blue.600"
                  p="0.25rem"
                  borderRadius="0.25rem"
                  fontSize="1rem"
                  fontWeight="700"
                  letterSpacing="1.1"
                >
                  PRIVATE
                </Text>
              ) : null}
              <Text variant="details">
                {pluralize('post', post_count, true)}
              </Text>
              <Text variant="details">
                {pluralize('reply', comment_count, true)}
              </Text>
              <DisplayTags tags={tags} />
            </Flex>
            {latest_post_author_body && (
              <Flex
                width="100%"
                gap="0.25rem"
                alignItems="center"
                style={{ ...theme.styles.global.body2, fontSize: '14px' }}
                pr={1}
              >
                <ProfileCard
                  memberProfile={latest_post_author_body}
                  userAvatar={(
                    <UserAvatar
                      name={authorName}
                      image_url=""
                      avatarSize="XS"
                    />
                  )}
                />
                <Text fontWeight="700" mr="0.2rem" minWidth="fit-content">
                  {authorName}
                </Text>
                posted &ldquo;
                {parse(latest_post_title)}
                &ldquo;
                <Text
                  ml="0.5rem"
                  fontWeight="600"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {getRelativeTime(latest_post_created_at)}
                </Text>
              </Flex>
            )}
          </Box>
        );
      })}
    </VStack>
  );
};

export default Groups;
