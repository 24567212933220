import React from 'react';
import COLOR_VALUES from '../../styles/ColorValues';
import { ASCENDING_ORDER, DESCENDING_ORDER } from './TableOptions';

const FilterArrowsIcon = ({ width, height, sortDirection }) => (
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
    >
      <path
        d="M3.99999 0L7.46408 6.25002L0.535897 6.25002L3.99999 0Z"
        fill={
          sortDirection === ASCENDING_ORDER
            ? COLOR_VALUES.sort.ACTIVE
            : COLOR_VALUES.sort.NOT_ACTIVE
        }
      />
      <path
        d="M4.00001 14L0.535917 7.74998L7.4641 7.74998L4.00001 14Z"
        fill={
          sortDirection === DESCENDING_ORDER
            ? COLOR_VALUES.sort.ACTIVE
            : COLOR_VALUES.sort.NOT_ACTIVE
        }
      />
    </svg>
  </div>
);

export default FilterArrowsIcon;
