import React, { useState } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import {
  Box,
  chakra,
  Table,
  Tbody,
  Thead,
  Flex,
  Tr,
  Th,
  Td,
  HStack,
  Button,
  Text,
} from '@chakra-ui/react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DeleteModal from '../Modals/DeleteModal';
import DownloadButton from '../ElementaryComponents/DownloadButton';
import FilterArrowsIcon from './FilterArrows';
import REPORT_TYPES from '../Analytics/ReportType';
import COLOR_VALUES from '../../styles/ColorValues';
import { ASCENDING_ORDER, DESCENDING_ORDER } from './TableOptions';

const ActionsCell = ({ report, navigateView, loggedInUserId }) => {
  const canDelete = loggedInUserId === report.author_id;
  const handleView = () => navigateView(report);

  return (
    <HStack minWidth="220px" justifyContent="flex-end" spacing={2}>
      {
        report.report_status !== 'Processing' && canDelete && (
          <DeleteModal itemId={report._id} deleteType="report" isIcon variant="outline" customBtnStyle={{ color: 'red' }} />
        )
      }
      {report.report_status !== 'Processing' && report.report_status !== 'Failed' && (
        <>
          <Button variant="noOutline" onClick={handleView}>View</Button>
          {report.report_sub_type !== 'moderator' && (
            <DownloadButton report_id={report._id} fileName={report?.file_object?.file_name} variant="outline" buttonLabel="Download" size="md" />
          )}
        </>
      )}
      {report.report_status === 'Failed' && <Text color="red.600" fontWeight="bold">Failed</Text>}
      {report.report_status === 'Processing' && <Text>Report is Processing</Text>}
    </HStack>
  );
};

const DataTable = ({
  tableData,
  sorting,
  onSortingChange,
  isSortOrderDesc,
  selectedTab,
}) => {
  const navigate = useNavigate();
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const [sortConfig, setSortConfig] = useState(
    isSortOrderDesc ? DESCENDING_ORDER : ASCENDING_ORDER,
  );

  const navigateView = (report) => {
    const reportDetails = {
      startDate: moment(report.start_date).format('MM/DD/YY'),
      endDate: moment(report.end_date).format('MM/DD/YY'),
      reportType: report.report_type,
      createdAt: moment(report.created_at).format('h:mma MM/DD/YY'),
      createdBy: `${report.author_body.first_name} ${report.author_body.last_name}`,
      subGroupName: report.report_meta?.subgroup_name,
    };

    if (report.report_sub_type === REPORT_TYPES.INFRACTION) {
      navigate(`/moderation-report/${report._id}`, {
        state: {
          author_id: report?.author_id,
          type: REPORT_TYPES.INFRACTION,
          reportDetails,
        },
      });
    } else if (report.report_sub_type === REPORT_TYPES.MODERATOR) {
      navigate(`/moderation-report/${report._id}`, {
        state: {
          author_id: report?.author_id,
          type: REPORT_TYPES.MODERATOR,
          reportDetails,
        },
      });
    } else {
      navigate(`/analytics/${report._id}`, {
        state: {
          author_id: report?.author_id,
          type: REPORT_TYPES.ANALYTIC,
          reportDetails,
        },
      });
    }
  };

  const reportTypeToTitleMap = (reportType, reportMeta) => {
    switch (reportType) {
      case 'all':
        return 'All';
      case 'lea':
        return reportMeta.subgroup_name;
      case 'moderator':
        return 'Moderator Report';
      default:
        return reportType;
    }
  };

  const columnHelper = createColumnHelper();

  const columns = [
    // A) Combine `start_date` and `end_date` in one column
    columnHelper.accessor(
      (row) => `${moment(row.start_date).format('MM/DD/YY')} to ${moment(
        row.end_date,
      ).format('MM/DD/YY')}`,
      {
        id: 'start_date',
        header: 'Report Dates',
        /* eslint-disable react/no-unstable-nested-components */
        // This rule is disabled here because TanStack Table (react-table) requires inline functions
        // for column cell rendering. Moving them outside makes it harder to pass `info` properly.
        cell: (info) => {
          return (
            <Box
              textAlign="left"
              whiteSpace="nowrap"
              boxSizing="border-box"
            >
              {info.getValue()}
            </Box>
          );
        },
        meta: {
          width: '7.625rem',
        },
      },
    ),

    // B) Access report_type using accessorKey
    columnHelper.accessor('report_type', {
      header: selectedTab === 0 ? 'Infractions' : selectedTab === 1 ? 'Group' : 'Activity',
      /* eslint-disable react/no-unstable-nested-components */
      cell: (info) => {
        const report = info.row.original;
        const mappedTitle = reportTypeToTitleMap(
          info.getValue(),
          report.report_meta,
        );
        return (
          <Box textAlign="left" whiteSpace="nowrap">
            {mappedTitle}
          </Box>
        );
      },
      meta: {
        width: '7.625rem',
      },
    }),

    // C) Access report_sub_type using accessorKey
    columnHelper.accessor('created_at', {
      header: 'Report Generated',
      enableSorting: false,
      cell: (info) => (
        <Box
          textAlign="left"
          whiteSpace="wrap"
          boxSizing="border-box"
        >
          {moment(info.getValue()).format('h:mma MM/DD/YY')}
        </Box>
      ),
      meta: {
        width: '9.625rem',
      },
    }),

    // D) Format author name from `author_body`
    columnHelper.accessor(
      (row) => {
        if (!row.author_body) return 'No Author';
        const { first_name, last_name } = row.author_body;
        return `${first_name} ${last_name}`;
      },
      {
        id: 'last_name',
        header: 'Created By',
        cell: (info) => (
          <Box
            textAlign="left"
            whiteSpace="wrap"
            boxSizing="border-box"
          >
            {info.getValue()}
          </Box>
        ),
        meta: {
          width: '7.625rem',
        },
      },
    ),

    columnHelper.accessor('actions', {
      header: '',
      enableSorting: false,
      /* eslint-disable react/no-unstable-nested-components */
      cell: ({ row }) => (
        <ActionsCell
          report={row.original}
          navigateView={navigateView}
          loggedInUserId={loggedInUserId}
        />
      ),
      meta: { width: '5.625rem' },
    }),
  ];

  const handleSortChange = (columnId) => {
    const sort = sorting.find((s) => s.id === columnId);

    if (sort) {
      setSortConfig(
        sort.desc ? ASCENDING_ORDER : sort.desc === false ? DESCENDING_ORDER : undefined,
      );
      onSortingChange([{ id: columnId, desc: !sort.desc }]);
    } else {
      setSortConfig(DESCENDING_ORDER);
      onSortingChange([{ id: columnId, desc: true }]);
    }
  };

  const { getHeaderGroups, getRowModel } = useReactTable({
    data: tableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),

    initialState: {
      sorting,
    },
  });

  return (
    <Flex
      flexDir="column"
      justifyContent="flex-end"
      alignItems="flex-end"
      w="full"
    >
      <Box overflow="hidden" borderRadius="0.313rem" w="full">
        {tableData?.length > 0 ? (
          <Table>
            <Thead
              borderBottom="0.125rem"
              borderBottomColor={`var(--The-Exchange-Color-Palette-Gray-100, ${COLOR_VALUES.gray[100]})}`}
            >
              {getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const sortEntry = sorting.find((s) => s.id === header.id);
                    const isEnableSorting = header.column.columnDef.enableSorting !== false;
                    const isSorted = !!sortEntry;
                    return (
                      <Th
                        key={header.id}
                        cursor={isEnableSorting ? 'pointer' : 'default'}
                        color="black"
                        fontFamily="Open Sans"
                        fontSize="1.25rem"
                        fontStyle="normal"
                        fontWeight="700"
                        lineHeight="1rem"
                        textTransform="none"
                        tabIndex={isSorted ? 0 : -1}
                        onClick={() => isEnableSorting
                          && header.column.id
                          && handleSortChange(header.column.id)}
                        onKeyUp={(e) => {
                          if (
                            isEnableSorting
                            && isSorted
                            && e.key === 'Enter'
                          ) {
                            handleSortChange(header.column.id);
                          }
                        }}
                        verticalAlign="top"
                        textAlign="left"
                        paddingLeft="0"
                        isNumeric={false}
                        _first={{ borderTopLeftRadius: '0.313rem' }}
                        _last={{ borderTopRightRadius: '0.313rem' }}
                      >
                        <Flex alignItems="center" justifyContent="left">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}

                          {isEnableSorting && (
                          <chakra.span
                            fontSize="0.75rem"
                            color="gray.500"
                            ml="0.5rem"
                            w="0.5rem"
                            height="0.875rem"
                            display="inline-block"
                          >
                            <FilterArrowsIcon
                              width="0.5rem"
                              height="0.875rem"
                              sortDirection={
                                    isSorted ? sortConfig : undefined
                                  }
                            />
                          </chakra.span>
                          )}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {getRowModel().rows.map((row) => (
                <Tr
                  key={row.id}
                  cursor="pointer"
                  className="table-row table-stripe"
                >
                  {row.getVisibleCells().map((cell) => {
                    const { meta } = cell.column.columnDef;
                    const columnId = cell.column.id;
                    const isReportDatesOrGroupColumn = columnId === 'start_date' || columnId === 'report_type';
                    return (
                      <Td
                        key={cell.id}
                        textAlign="center"
                        cursor="default"
                        color={`var(--The-Exchange-Color-Palette-Gray-800, ${COLOR_VALUES.gray[800]})`}
                        fontStyle="normal"
                        fontFamily="Open Sans"
                        fontWeight={isReportDatesOrGroupColumn ? '700' : '400'}
                        lineHeight="1.5rem"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="wrap"
                        width={meta.width}
                        paddingLeft="0"
                        className="table-cell"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Td>
                    );
                  })}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <Box w="full" h="full" p="2rem" background="gray.50">
            <Flex flexDir="column" alignItems="center" justifyContent="center" gap="0.25rem">
              <h4>No Reports Have Been Generated</h4>
              <Box fontWeight="400">
                Create a report by filling out the fields and
                clicking &quot;Create Report&quot; above.
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default DataTable;
