import { useLoadingState } from '@texas-education-exchange/edx-portal-shared';
import { useEffect, useState } from 'react';

import { useAuthorization } from './authorization.hook';
import { useUserLoading } from './user.hooks';
import { useAppConfigurationSubscription, useOpenIdConfigurationSubscription } from '../services/apis/configurationAPI';

const configLoadingMessage = 'Loading configuration...';
const profileLoadingMessage = 'Loading profile...';
const authorizationPendingMessage = 'Checking authorization...';

const useAppLoadingState = () => {
  const { isFetching: isAppConfigPending } = useAppConfigurationSubscription();
  const { isFetching: isOpenIdConfigPending } = useOpenIdConfigurationSubscription();
  const { isPending: isAuthorizationPending } = useAuthorization();
  const isUserPending = useUserLoading();

  const { loadingState, stateMessage } = useLoadingState();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      setLoaded(loadingState === 'finished');
    }
  }, [loadingState, loaded]);

  // Check if the app is still loading
  const pending = loadingState !== 'finished'
  || isAppConfigPending
  || isOpenIdConfigPending
  || isAuthorizationPending
  || isUserPending;

  // Decide Which message to display
  let finalState;

  if (loadingState !== 'finished') {
    // If we're not finished with our top-level loading state, use stateMessage
    finalState = stateMessage;
  } else if (isAppConfigPending || isOpenIdConfigPending) {
    // If fetching app/openID config
    finalState = configLoadingMessage;
  } else if (isUserPending) {
    // If user profile is still loading
    finalState = profileLoadingMessage;
  } else if (isAuthorizationPending) {
    // If authorization checks are pending
    finalState = authorizationPendingMessage;
  } else {
    // Otherwise, no additional message
    finalState = '';
  }

  return {
    pending,
    state: finalState,
  };
};

export default useAppLoadingState;
