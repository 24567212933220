import {
  useAuth,
  TEEAuthDataContext,
} from '@texas-education-exchange/edx-portal-shared';
import { useContext } from 'react';
import { useUserDataContext } from './user.hooks';

export function useAuthDataContext() {
  return useContext(TEEAuthDataContext);
}

export function useAuthenticationState() {
  const {
    user,
    isAuthenticated,
    isLoading,
    error,
    events,
    activeNavigator,
    signinRedirect,
  } = useAuth();

  return {
    user,
    isAuthenticated,
    isLoading,
    error,
    events,
    activeNavigator,
    signinRedirect,
  };
}

export function useUserProfile() {
  const { userProfile, loadingProfile } = useUserDataContext();
  return {
    userProfile,
    loadingProfile,
  };
}

export function useActiveTenant() {
  const { userProfile } = useUserProfile();
  return userProfile?.selectedTenant;
}
