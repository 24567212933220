import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { store } from './app/store';

import reportWebVitals from './reportWebVitals';
import Main from './main';

const root = document.querySelector('#root');

if (!root) {
  throw new Error('Root element not found');
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <BrowserRouter basename={process.env.REACT_APP_BASE_PATH ?? ''}>
        <Main />
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
);
