import React from 'react';
import { LoadingScreen } from '@texas-education-exchange/edx-portal-shared';
import useAppLoadingState from '../hooks/loading.hooks';

const AppLoader = () => {
  const { state, pending } = useAppLoadingState();

  return (
    <>
      {pending ? (
        <LoadingScreen state={state} loading={pending} delay={500} />
      ) : (
        <></>
      )}
    </>
  );
};

export default AppLoader;
