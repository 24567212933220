import { useContext } from 'react';
import { ConfigurationContext } from '../hoc/configuration';

export function useConfiguration() {
  const context = useContext(ConfigurationContext);

  if (!context) {
    throw new Error(
      'useAppConfiguration has to be used within <AppConfigurationContext.Provider>',
    );
  }
  return context;
}

export function useExchangeConfiguration() {
  const { app, api, auth } = useConfiguration();
  return {
    app,
    api,
    auth,
  };
}

export function useExchangeAuthConfiguration() {
  const { auth } = useConfiguration();
  return auth;
}

export function useExchangeAppConfiguration() {
  const { app } = useConfiguration();
  return app;
}
