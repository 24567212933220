import React from 'react';
import { useAuthorization } from '../hooks/authorization.hook';
import NotAuthorized from '../pages/NotAuthorized';

const Authorization = ({ children }) => {
  const { isPending, isNotAuthorized } = useAuthorization();

  if (isPending) {
    return null;
  }
  if (isNotAuthorized) {
    return <NotAuthorized />;
  }
  return children;
};

export default Authorization;
