import React, { useState } from 'react';
import {
  Flex, SkipNavContent,
} from '@chakra-ui/react';
import { Content } from '@texas-education-exchange/edx-portal-shared';
import LeftSideBar from './LeftSideBar';
import AppRouter from '../routes/AppRouter';

const RootLayout = ({ userData }) => {
  const [navExpanded, setNavExpanded] = useState(false);

  return (
    <Flex w="full">
      <Flex w="full">
        <Flex minH="100vh" mt="" h="auto">
          <LeftSideBar
            navExpanded={navExpanded}
            setNavExpanded={setNavExpanded}
            access_sidebar={userData?.access_sidebar}
            userData={userData}
            ariaCurrentType="page"
          />
        </Flex>
        <Flex flex="1" marginLeft="20px" marginTop="10px" transition="margin-left 0.3s">
          <SkipNavContent />
          <Content marginTop="0px" maxW="1800px" w="full">
            <AppRouter />
          </Content>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default RootLayout;
