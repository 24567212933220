import { UserProfileContext } from '@texas-education-exchange/edx-portal-shared';
import { useContext, useEffect, useState } from 'react';

export function useUserDataContext() {
  return useContext(UserProfileContext);
}

export function useUserLoading() {
  const { loadingProfile } = useUserDataContext();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded && loadingProfile) {
      setLoaded(loadingProfile);
    }
  }, [loadingProfile, loaded]);

  return loadingProfile && !loaded;
}
