import {
  skipToken,
} from '@reduxjs/toolkit/query/react';
import apiService from '../apiService';

const APP_CONFIGURATION_TAG = 'AppConfiguration';
const OPENID_CONFIGURATION_TAG = 'OpenIdConfiguration';

const configPath = process.env.REACT_APP_BASE_PATH ? `${process.env.REACT_APP_BASE_PATH}/config.json` : '/config.json';

export const configurationAPI = apiService.injectEndpoints({
  endpoints: (builder) => {
    return ({
      getAppConfiguration: builder.query({
        query: () => configPath,
        providesTags: [APP_CONFIGURATION_TAG],
      }),
      getOpenIdConfiguration: builder.query({
        query: (path) => path,
        providesTags: [OPENID_CONFIGURATION_TAG],
      }),
    });
  },
});

export const {
  useGetAppConfigurationQuery, useGetOpenIdConfigurationQuery,
} = configurationAPI;

const { endpoints } = configurationAPI;
const { getAppConfiguration, getOpenIdConfiguration } = endpoints;

export const {
  useQueryState: useGetAppConfigurationState,
  useQuerySubscription: useGetAppConfigurationSubscription,
} = getAppConfiguration;

export const {
  useQueryState: useGetOpenIdConfigurationState,
  useQuerySubscription: useGetOpenIdConfigurationSubscription,
} = getOpenIdConfiguration;

export function useOpenIdConfigurationUrl() {
  return useGetAppConfigurationQuery(undefined, {
    selectFromResult: ({ data }) => ({
      url: data?.auth.configurationUri,
    }),
  });
}

export function useExchangeApiPath() {
  return useGetAppConfigurationQuery(undefined, {
    selectFromResult: ({ data }) => ({
      url: data?.api.baseUri,
    }),
  });
}

export function useAppConfigurationSubscription() {
  const state = useGetAppConfigurationState();
  useGetAppConfigurationSubscription();

  return state;
}

export function useOpenIdConfigurationSubscription(path) {
  const state = useGetOpenIdConfigurationState(path ?? skipToken);
  useGetOpenIdConfigurationSubscription(path ?? skipToken);

  return state;
}
