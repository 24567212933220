import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useTheme,
  Flex,
  Box,
  Badge,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { Info } from '@mui/icons-material';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import { useSelector } from 'react-redux';
import {
  useUpdateUserAgreementStatusMutation,
  useUpdateUserAgreementStatusToDeclinedMutation,
} from '../../services/apis/usersAPI';
import { useUserData } from '../../hoc/userdata-context';
import { useFetchAgreementByIdQuery } from '../../services/apis/agreementsAPI';
import AGREEMENT_CONSTANT from './Agreement.constant';

const EULAModal = ({
  custom_btn_style = {},
  isOpen,
  onClose,
}) => {
  const theme = useTheme();
  const { userData } = useUserData();
  const { auth } = useContext(TEEAuthDataContext);
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);

  const [isEULAAgreed, setIsEULAAgreed] = useState(false);

  const agreementId = userData?.data?.latest_agreements?.eula?.agreement.id;
  const latestEULAStatus = userData?.data?.latest_agreements?.eula?.status;

  const subLinkStyle = {
    minWidth: 'max-content',
    ...theme.styles?.global?.body2,
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 500,
    ...custom_btn_style,
  };

  const closeModal = () => {
    onClose();
  };

  const handleNotAcceptingEULA = () => {
    setShowLogoutAlert(true);
  };

  const {
    data: agreementData,
    isFetching: isAgreementFetching,
  } = useFetchAgreementByIdQuery(agreementId);

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const EULAVersion = agreementData?.data?.version;
  const modalHeaderText = agreementData?.data?.display_name;

  const [updateUserAgreementStatus] = useUpdateUserAgreementStatusMutation();
  const [updateUserAgreementStatusToDeclined] = useUpdateUserAgreementStatusToDeclinedMutation();

  const handleAgreementUpdate = async () => {
    if (loggedInUserId) {
      try {
        await updateUserAgreementStatus({
          userId: loggedInUserId, agreementId,
        }).unwrap();
        window.location.reload();
        closeModal();
      } catch (error) {
        console.error('Error while updating user agreement status', error);
      }
    }
  };

  const handleLogout = async () => {
    if (loggedInUser) {
      try {
        await updateUserAgreementStatusToDeclined({
          userId: loggedInUserId, agreementId,
        }).unwrap();
      } catch (error) {
        console.error('Error while updating user agreement status to declined', error);
      }
      auth.signoutRedirect();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        size="4xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          borderTop="0.5rem solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
          aria-label="eula modal"
        >
          <ModalHeader display="flex" flexDir="column" gap="0.5rem" fontFamily="poppins">
            <h1>{AGREEMENT_CONSTANT.AGREEMENT_MODAL_HEADER_TEXT}</h1>
          </ModalHeader>
          <ModalBody paddingTop="0">
            <Box fontWeight="400" fontSize="1.125rem" mb="1rem" lineHeight="1.625rem" fontStyle="italic" fontFamily="open sans">
              {AGREEMENT_CONSTANT.AGREEMENT_MODAL_SUB_HEADER_TEXT}
            </Box>
            <Flex justifyContent="space-between" alignItems="center" mb="0.5rem">
              <Box>
                {modalHeaderText}
                {' '}
                v
                {EULAVersion}
                {' '}
                (Required)
              </Box>
              {latestEULAStatus === 'pending'
                ? (
                  <Badge background={theme.colors.orange[400]} variant="solid" size="sm" color="black" borderRadius="0.25rem" lineHeight="1.25rem" px="0.25rem">
                    Part 1 of 2
                  </Badge>
                )
                : null}
            </Flex>
            <Flex
              tabIndex={0}
              flexDirection="column"
              gap="1rem"
              className="eula"
              maxHeight="34.375rem"
              overflowY="scroll"
              border="0.063rem solid #CED8DD"
              p={2}
              width="100%"
              height="100%"
            >
              {isAgreementFetching
                ? (
                  <Flex justify="center" align="center" height="12.5rem">
                    <Spinner size="lg" color="blue.500" />
                  </Flex>
                )
                : (
                  <>
                    {parse(agreementData?.data?.content || '')}
                  </>
                )}
            </Flex>
          </ModalBody>

          <ModalFooter flexDir="column" gap="1rem">
            <Flex
              alignContent="center"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
            >
              <Flex flexDir="column" gap="0.5rem" alignItems="flex-start">
                <Box>
                  <Checkbox
                    sx={{ fontSize: '0.875rem !important', fontWeight: '400', fontFamily: 'open sans' }}
                    isChecked={isEULAAgreed}
                    onChange={() => setIsEULAAgreed(!isEULAAgreed)}
                  >
                    {AGREEMENT_CONSTANT.EULA_ACCEPT_CHECKBOX_TEXT}
                  </Checkbox>
                </Box>
                <Box fontFamily="open sans" justifyContent="flex-start" padding="0">
                  <Button
                    onClick={() => handleNotAcceptingEULA()}
                    style={{
                      ...subLinkStyle, textDecoration: 'underline', color: '#455763', fontSize: '0.875rem',
                    }}
                  >
                    {AGREEMENT_CONSTANT.EULA_DECLINE_LABEL_TEXT}
                  </Button>
                </Box>
              </Flex>
              <Box
                bg="blue.500"
                color="white"
                borderRadius="0.25rem"
                padding="0.5rem 1rem"
                opacity={isEULAAgreed ? 1 : 0.5}
              >
                <Button color="white" isDisabled={!isEULAAgreed} onClick={() => handleAgreementUpdate()} cursor={isEULAAgreed ? 'pointer' : 'not-allowed'}>
                  Continue
                </Button>
              </Box>
            </Flex>

            {showLogoutAlert && (
            <Flex
              w="100%"
              justifyContent="space-between"
              background={theme.colors.orange[100]}
              padding="1rem"
              borderLeft="0.33rem solid"
              borderColor={theme.colors.orange[500]}
            >
              <Flex gap="0.5rem">
                <Info sx={{ color: theme.colors.orange[500] }} />
                <Flex flexDir="column" gap="0rem" marginLeft="0.25rem">
                  <Box fontSize="1rem">{AGREEMENT_CONSTANT.EULA_LOGOUT_ALERT_TITLE_TEXT}</Box>
                  <Box fontWeight="400" fontSize="0.875rem" fontFamily="open sans">
                    {AGREEMENT_CONSTANT.EULA_LOGOUT_ALERT_BODY_TEXT}
                  </Box>
                </Flex>
              </Flex>
              <Flex alignItems="center">
                <Button
                  size="md"
                  onClick={() => handleLogout()}
                  border="0.063rem solid"
                  borderColor={theme.colors.orange[400]}
                  color={theme.colors.orange[600]}
                  bg="white"
                  _hover={{ bg: '#FFEDD5' }}
                  borderRadius="0.25rem"
                  fontSize="0.875rem"
                  padding="1rem 1rem !important"
                >
                  {AGREEMENT_CONSTANT.EULA_LOGOUT_CONFIRMATION_BUTTON_LABEL}
                </Button>
              </Flex>
            </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EULAModal;
