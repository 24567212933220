import apiService from '../apiService';

export const agreementsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAgreementById: builder.query({
      query: (agreementUUID) => ({
        url: `/agreements/v1/${agreementUUID}`,
      }),
      providesTags: ['agreements'],
      invalidatesTags: ['agreements'],
    }),
  }),
});

export const {
  useFetchAgreementByIdQuery,
} = agreementsAPI;
