/* eslint-disable no-unused-vars */
import apiService from '../apiService';

export const groupsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllGroups: builder.query({
      query: ({
        page_size, page_number, sort_order, pin, sort_by,
        query, last_created, include_apps, include_courses,
      }) => ({
        url: '/groups/v1',
        params: {
          page_size,
          page_number,
          sort_order,
          sort_by,
          pin,
          query,
          last_created,
          include_apps,
          include_courses,
        },
      }),
      providesTags: ['groups'],
    }),
    fetchGroupById: builder.query({
      query: (id) => ({
        url: `groups/v1/${id}`,
      }),
      providesTags: ['group'],
    }),
    updateGroupById: builder.mutation({
      query: ({ group_id, groupData }) => ({
        url: `groups/v1/${group_id}`,
        method: 'PUT',
        body: groupData,
      }),
      onQueryStarted: async (arg, { queryFulfilled, dispatch }) => {
        try {
          await queryFulfilled;
          dispatch(apiService.util.invalidateTags(['groups', 'group']));
        } catch {
          console.error('Error');
        }
      },
    }),
    deleteGroup: builder.mutation({
      query: (groupId) => ({
        url: `groups/v1/${groupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['groups', 'course'],
    }),
    createGroup: builder.mutation({
      query: (newGroupData) => ({
        url: 'groups/v1',
        method: 'POST',
        body: newGroupData,
      }),
      invalidatesTags: ['groups', 'updates'],
    }),
    fetchGroupUpdates: builder.query({
      query: (paramsObj) => ({
        url: '/groups/v1/updates',
        params: paramsObj,
      }),
      providesTags: ['group-updates'],
    }),
    fetchGroupStateCheck: builder.query({
      query: ({ group_id, visibility }) => ({
        url: `/groups/v1/${group_id}/statecheck?visibility=${visibility}`,
      }),
      providesTags: ['group-state-check'],
    }),
  }),
});

export const {
  useFetchAllGroupsQuery,
  useFetchGroupByIdQuery,
  useDeleteGroupMutation,
  useUpdateGroupByIdMutation,
  useCreateGroupMutation,
  useFetchGroupUpdatesQuery,
  useFetchGroupStateCheckQuery,
} = groupsAPI;
