import React from 'react';
import {
  VStack, useTheme, Box, Flex, Text,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import LockIcon from '@mui/icons-material/Lock';
import pluralize from 'pluralize';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import { MoreVertIcon } from '../../icons/index';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import ActionPopOver from '../PopOver/ActionPopOver';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import { formatStatusText, getRelativeTime, joinTruthyStrings } from '../../utils/helpers';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';
import FlaggedItemTitle from '../ElementaryComponents/FlaggedItemTitle';

const Posts = ({
  postsData, noDataMessage, courseGroupId = '', no_action_in_group = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { group_id } = useParams();
  const displayedPosts = postsData?.data;
  const postsPermissions = postsData?.permissions?.post;
  const can_view_hidden = postsPermissions?.can_view_hidden;
  const accessibilityKeyHandler = useAccessibleNavigation();
  // Todod: update the below action var condition once updated from the API.
  // no_action_in_group

  return (
    displayedPosts?.length === 0
      ? <DataUnavailable display_message={noDataMessage} />
      : (
        <VStack
          style={{
            ...theme.styles.global.container,
          }}
          minHeight="4rem"
          padding="1rem"
          alignSelf="flex-end"
          spacing={2}
          alignItems="flex-start"
        >
          {
            displayedPosts?.map((postData) => {
              const {
                created_at,
                tags,
                title,
                view_count,
                like_count,
                comment_count,
                _id,
                pin,
                author_id,
                author_body = {}, // may not exist in the post data
                latest_comment,
                is_locked,
                is_hidden,
                user_locked,
                user_banned,
                author_banned,
                is_access_locked,
              } = postData;

              const { status, formatedStatusText, showFlaggedItemTitle } = formatStatusText({
                can_view_hidden,
                includeStatusText: true,
                is_locked,
                is_hidden,
                user_locked,
                user_banned,
              });

              let authorName = joinTruthyStrings({
                strings: [author_body.first_name, author_body.last_name],
                connectingString: ' ',
              });
              if (!authorName) authorName = author_id;

              const createPinBodyObj = {
                parent_object_id: group_id,
                parent_object_type: 'group',
                child_object_id: _id,
                child_object_type: 'post',
              };
              const isLatestComment = latest_comment?.author_body;
              const latestCommentAuthor = joinTruthyStrings({
                strings: [
                  latest_comment?.author_body?.first_name,
                  latest_comment?.author_body?.last_name,
                ],
                connectingString: ' ',
              });
              const navigatePath = `/forum/${group_id || courseGroupId}/${_id}`;
              return (
                <VStack
                  className="postCard"
                  key={_id}
                  tabIndex={0}
                  role={can_view_hidden ? 'button' : 'link'}
                  aria-disabled={can_view_hidden && 'true'}
                  onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath)}
                  p="0.5rem 0"
                  width="100%"
                  mt={0}
                  cursor="pointer"
                  onClick={() => {
                    navigate(navigatePath);
                  }}
                >
                  <Flex justify="space-between" width="100%">
                    <Box>
                      {showFlaggedItemTitle ? (
                        <FlaggedItemTitle
                          title={title}
                          status={status}
                          formatedStatusText={formatedStatusText}
                        />
                      ) : (
                        <Flex alignItems="center" justifyContent="center">
                          {is_access_locked ? <LockIcon /> : null}
                          <Text
                            style={{
                              ...theme.styles.global.h4,
                              display: 'inline-block',
                              fontWeight: 700,
                            }}
                            color="gray.800"
                          >
                            {parse(title)}
                          </Text>
                        </Flex>
                      )}
                    </Box>
                    <Flex justify="space-between" alignItems="center" mr="0.5rem">
                      <TogglePinIcon
                        is_pinned={pin}
                        create_pin_body_obj={createPinBodyObj}
                        pin_id={postData?.pin_id}
                      />
                      {
                        !(no_action_in_group || showFlaggedItemTitle) && (
                        <ActionPopOver
                          moreVertPopOver={<MoreVertIcon cursor="pointer" />}
                          content_type="post"
                          content_id={_id}
                        />
                        )
                      }
                    </Flex>
                  </Flex>

                  <Flex width="100%" gap="2rem">

                    <Text style={{ ...theme.styles.global.body, fontWeight: '400' }}>
                      Posted by
                      <span
                        style={{
                          fontWeight: '700',
                          color: author_banned ? theme.colors.red[700] : theme.colors.gray[500],
                          fontFamily: 'Archivo Narrow',
                          marginRight: '0.5rem',
                        }}
                      >
                        {' '}
                        {author_banned ? '[This user was banned from this group]' : authorName}
                      </span>
                      {getRelativeTime(created_at)}
                    </Text>

                    <Flex gap="1rem">
                      <Text variant="details">
                        {pluralize('like', like_count, true)}
                      </Text>
                      <Text variant="details">
                        {pluralize('reply', comment_count, true)}
                      </Text>
                      <Text variant="details">
                        {pluralize('view', view_count, true)}
                      </Text>
                    </Flex>

                    <DisplayTags tags={tags} />

                  </Flex>
                  {
                    isLatestComment && (
                    <Flex
                      width="100%"
                      alignItems="center"
                      gap="0.25rem"
                      style={{ ...theme.styles.global.body2, fontSize: '14px' }}
                    >
                      <Text> Latest: reply by&nbsp;</Text>
                      <ProfileCardPopOver
                        memberProfile={latest_comment.author_body}
                        userAvatar={(
                          <UserAvatar
                            name={latestCommentAuthor}
                            user_profile_pic=""
                            avatarSize="XS"
                          />
                        )}
                      />
                      <Text fontWeight="700">
                        {latestCommentAuthor}
                      </Text>
                      <Text ml="0.5rem">{getRelativeTime(latest_comment?.created_at)}</Text>
                    </Flex>
                    )
                  }
                </VStack>
              );
            })
      }
        </VStack>
      )
  );
};

export default Posts;
