import React from 'react';
import GuidelinesModal from './GuidelinesModal';

const DefaultOpenGuidlinesModal = ({
  isOpen,
  onOpen,
  onClose,
  showPartTwoBadge,
}) => {
  return (
    <GuidelinesModal
      custom_btn_style={{ display: 'hidden' }}
      btn_type="text"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      showPartTwoBadge={showPartTwoBadge}
    />
  );
};

export default DefaultOpenGuidlinesModal;
