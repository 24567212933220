/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Flex, Text, useTheme } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import {
  getRelativeTime,
  capitalizeFirstLetter,
  joinTruthyStrings,
  formatStatusText,
} from '../../utils/helpers';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';
import FlaggedAuthorName from '../ElementaryComponents/FlaggedAuthorName';
import FlaggedItemTitle from '../ElementaryComponents/FlaggedItemTitle';
import { LockIcon } from '../../icons';

const SearchResultCard = ({ resultObj = {} }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const accessibilityKeyHandler = useAccessibleNavigation();
  const {
    _id,
    group_id = '',
    post_id = '',
    title,
    description = '',
    created_at,
    tags,
    like_count,
    content_type,
    author_body = {},
    is_hidden,
    is_locked,
    author_banned,
    author_locked,
    author_flagged,
    user_locked,
    user_banned,
    visibility,
    is_access_locked,
  } = resultObj;

  const capitalizeContentType = capitalizeFirstLetter(content_type);
  const customSearchedItemTitle = `${
    capitalizeContentType === 'Course' ? 'Training' : capitalizeContentType
  }${description ? `: ${title}` : ''}`;
  const {
    user_profile_pic = '',
    first_name = '',
    last_name = '',
  } = author_body;

  let navigatePath;
  switch (content_type) {
    case 'group':
      navigatePath = `/forum/${_id}`;
      break;
    case 'post':
      navigatePath = `/forum/${group_id}/${_id}`;
      break;
    case 'course':
      navigatePath = `/training/${_id}`;
      break;
    case 'event':
      navigatePath = `/events/${_id}`;
      break;
    case 'comment':
      navigatePath = `/forum/${group_id}/${post_id}/${_id}`;
      break;
    default:
      navigatePath = '/search';
  }

  let authorName = joinTruthyStrings({
    strings: [first_name, last_name],
    connectingString: ' ',
  });
  if (!authorName) authorName = _id;

  const { status, formatedStatusText, showFlaggedItemTitle } = formatStatusText(
    {
      can_view_hidden: false,
      includeStatusText: true,
      is_locked,
      is_hidden,
      user_locked,
      user_banned,
    },
  );

  return (
    <Flex
      key={_id}
      tabIndex={0}
      role="link"
      onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath)}
      onClick={() => navigate(navigatePath)}
      flexDir="column"
      style={{
        borderBottom: '1px solid #CED8DD',
        cursor: 'pointer',
      }}
      mb="1.5rem"
      pb=".5rem"
    >
      <Flex>
        {is_access_locked ? <LockIcon /> : null}
        {showFlaggedItemTitle ? (
          <FlaggedItemTitle
            title={content_type === 'comment' ? 'Reply' : title}
            status={status}
            formatedStatusText={formatedStatusText}
          />
        ) : (
          <Text
            style={{
              ...theme.styles.global.h4,
              fontWeight: 600,
              marginBottom: '0.25rem',
            }}
          >
            {parse(customSearchedItemTitle)}
          </Text>
        )}
      </Flex>

      <Flex alignItems="center" gap="0.5rem" mb="0.5rem">
        {visibility && visibility !== 'public' ? (
          <Text
            background={theme.colors.blue['100']}
            color="blue.600"
            p="0.25rem"
            borderRadius="0.25rem"
            fontSize="1rem"
            fontWeight="700"
            letterSpacing="1.1"
          >
            PRIVATE
          </Text>
        ) : null}
        {author_body?._id && (
          <>
            <Text variant="details">created by:</Text>
            {author_flagged ? (
              <FlaggedAuthorName
                author_name={authorName}
                author_banned={author_banned}
                author_locked={author_locked}
              />
            ) : (
              <>
                <ProfileCardPopOver
                  memberProfile={author_body}
                  userAvatar={(
                    <UserAvatar
                      name={authorName}
                      user_profile_pic={user_profile_pic}
                      avatarSize="XS"
                    />
                  )}
                />
                <Text variant="author">
                  <strong>{authorName}</strong>
                </Text>
              </>
            )}
            <Text mr="0.5rem">{getRelativeTime(created_at)}</Text>
          </>
        )}
        {like_count > 0 && (
          <Text variant="details">{pluralize('like', like_count, true)}</Text>
        )}
        <DisplayTags tags={tags} />
      </Flex>
      <Text style={{ fontWeight: '400' }} mb="0.5rem" className="overRide-bulletPoint-style">
        {parse(description || title)}
      </Text>
    </Flex>
  );
};

export default SearchResultCard;
