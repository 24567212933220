import React, { createContext } from 'react';
import { Alert, AlertDescription, AlertTitle } from '@chakra-ui/react';
import { useGetAppConfigurationQuery } from '../services/apis/configurationAPI';

export const ConfigurationContext = createContext(undefined);

const { Provider } = ConfigurationContext;

export const ConfigurationProvider = ({ children }) => {
  const {
    data, isFetching, isError, isSuccess,
  } = useGetAppConfigurationQuery();

  console.log('data', {
    data, isFetching, isError, isSuccess,
  });

  return (
    <>
      {isFetching ? (
        <></>
      ) : isError ? (
        <Alert status="error">
          <AlertTitle>Something went wrong!</AlertTitle>
          <AlertDescription>
            Unable to retrieve Application Configuration.
          </AlertDescription>
        </Alert>
      ) : (
        isSuccess && <Provider value={data}>{children}</Provider>
      )}
    </>
  );
};
