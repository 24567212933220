import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  SessionInactiveModal,
  useAuthActions,
  useAuthAutoRefresh,
  useIdleSession,
  useLoadingState,
} from '@texas-education-exchange/edx-portal-shared';
import { addMinutes, differenceInMilliseconds } from 'date-fns';
import { useAppDispatch } from '../hooks/store.hook';
import {
  useAuthToken,
  useHasValidToken,
  validateToken,
} from '../slices/authentication.slice';

import { useAuthenticationState } from '../hooks/authentication.hook';
import { useExchangeConfiguration } from '../hooks/configuration.hooks';
import NotAuthorized from '../pages/NotAuthorized';

const DEFAULT_IDLE_OFFSET_MINUTES = 10;
const now = new Date();
const adjusted = addMinutes(now, DEFAULT_IDLE_OFFSET_MINUTES);
const timeout = differenceInMilliseconds(adjusted, now);

const Authentication = ({ children }) => {
  useAuthAutoRefresh();

  const validToken = useAuthToken();
  const hasValidToken = useHasValidToken();
  const auth = useAuthenticationState();
  const dispatch = useAppDispatch();
  const { handleLogOut } = useAuthActions();
  const { loadingState } = useLoadingState();
  const { showInactiveModal, onCloseInactiveModal } = useIdleSession({
    timeout,
  });
  const [isClosingSession, setIsClosingSession] = useState(false);
  const config = useExchangeConfiguration();

  const onLogout = async () => {
    setIsClosingSession(true);
    await handleLogOut();
  };

  const dispatcher = useCallback(
    async (token, configurationUri) => {
      await dispatch(
        validateToken({
          token,
          configurationUri,
        }),
      );
    },
    [dispatch],
  );

  const close = useCallback(() => {
    setIsClosingSession(true);
    onCloseInactiveModal()
      .catch(console.error)
      .finally(() => {
        setIsClosingSession(false);
      });
  }, [onCloseInactiveModal]);

  const authUser = useMemo(() => auth?.user, [auth]);

  useEffect(() => {
    console.log('🛠 Checking Authentication State:', authUser);

    if (authUser && config?.auth?.configurationUri) {
      const { access_token } = authUser;

      console.log('🔍 Found access_token:', access_token);
      console.log('⚡ hasValidToken before dispatch:', hasValidToken);

      if (access_token && (!hasValidToken || access_token !== validToken)) {
        console.log('🚀 Dispatching validateToken...');
        dispatcher(access_token, config.auth.configurationUri).catch(console.error);
      }
    }
  }, [authUser, config, dispatcher, hasValidToken, validToken]);

  const abc = useAuthToken();
  useEffect(() => {
    console.log('Token in Redux after update:', abc);
  }, [abc]);

  console.log('hasValidToken', hasValidToken);
  return (
    <>
      {loadingState === 'finished' ? (
        <>
          {hasValidToken ? (
            <>
              {children}
              <SessionInactiveModal
                show={showInactiveModal}
                isClosingSession={isClosingSession}
                onLogout={onLogout}
                onClose={close}
              />
            </>
          ) : (
            <NotAuthorized />
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Authentication;
