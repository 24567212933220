import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useTheme,
  Text,
  Flex,
  Box,
  Badge,
  Checkbox,
  Spinner,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import {
  useUpdateUserAgreementStatusMutation,
  useUpdateUserAgreementStatusToDeclinedMutation,
} from '../../services/apis/usersAPI';
import { useUserData } from '../../hoc/userdata-context';
import { useFetchAgreementByIdQuery } from '../../services/apis/agreementsAPI';
import AGREEMENT_CONSTANT from './Agreement.constant';

const GuidelinesModal = ({
  custom_text = 'User Guidelines',
  custom_btn_style = {},
  btn_type = 'btn',
  buttonClass,
  isOpen,
  onOpen,
  onClose,
  showPartTwoBadge,
  guidelines_accepted,
}) => {
  const theme = useTheme();
  const { userData } = useUserData();

  const [isGuidelinesAgreed, setIsGuidelinesAgreed] = useState(false);
  const agreementId = userData?.data?.latest_agreements?.community_guidelines?.agreement?.id;

  const handleOnClick = (e) => {
    e.stopPropagation();
    onOpen();
  };
  const subLinkStyle = {
    minWidth: 'max-content',
    ...theme.styles?.global?.body2,
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 500,
    ...custom_btn_style,
  };

  const closeModal = () => {
    onClose();
  };

  const handleKeyDown = (event) => {
    if (
      event.key === 'Enter'
       || event.key === ' ' // Space key
    ) {
      event.preventDefault();
      handleOnClick(event);
    }
  };
  const buttonAttributes = buttonClass ? { className: buttonClass } : {};

  const {
    data: agreementData,
    isFetching: isAgreementFetching,
  } = useFetchAgreementByIdQuery(agreementId);

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const guidelinesVersion = agreementData?.data?.version;
  const modalHeaderText = agreementData?.data?.display_name;

  const [updateUserAgreementStatus] = useUpdateUserAgreementStatusMutation();

  const [updateUserAgreementStatusToDeclined] = useUpdateUserAgreementStatusToDeclinedMutation();

  const handleAgreementUpdate = async () => {
    if (loggedInUserId) {
      try {
        await updateUserAgreementStatus({
          userId: loggedInUserId, agreementId,
        }).unwrap();
        window.location.reload();
        closeModal();
      } catch (error) {
        console.error('Error while updating user agreement status', error);
      }
    }
  };

  const handleGuidelinesDeclined = async () => {
    if (loggedInUserId) {
      try {
        await updateUserAgreementStatusToDeclined({
          userId: loggedInUserId, agreementId,
        }).unwrap();
        window.location.reload();
        closeModal();
      } catch (error) {
        console.error('Error while updating user agreement declined status', error);
      }
    }
  };

  const updateGuidelinesStatus = async () => {
    if (isGuidelinesAgreed) {
      handleAgreementUpdate(isGuidelinesAgreed);
    } else {
      handleGuidelinesDeclined();
    }
  };

  return (
    <>
      {btn_type === 'text' ? (
        <Text
          tabIndex="0"
          aria-label="Open guidelines modal"
          onClick={handleOnClick}
          onKeyDown={handleKeyDown}
          as="p"
          style={subLinkStyle}
          {...buttonAttributes}
        >
          {custom_text}
        </Text>
      ) : (
        <Button
          colorScheme="blue"
          bg="blue.500"
          onClick={handleOnClick}
          {...buttonAttributes}
        >
          {custom_text}
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        size="4xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          borderTop="0.5rem solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
          aria-label="guidelines modal"
        >
          <ModalHeader display="flex" flexDir="column" gap="0.5rem" fontFamily="poppins">
            <h1>{AGREEMENT_CONSTANT.AGREEMENT_MODAL_HEADER_TEXT}</h1>
          </ModalHeader>

          <ModalBody paddingTop="0">
            <Box>
              <Box
                fontWeight="400"
                fontSize="1.125rem"
                mb="1rem"
                lineHeight="1.625rem"
                fontStyle="italic"
                fontFamily="open sans"
              >
                {AGREEMENT_CONSTANT.AGREEMENT_MODAL_SUB_HEADER_TEXT}
              </Box>

              <Flex justifyContent="space-between" alignItems="center" mb="0.5rem">
                <Box>
                  {modalHeaderText}
                  {' '}
                  v
                  {guidelinesVersion}
                </Box>
                {showPartTwoBadge && (
                <Badge background={theme.colors.orange[400]} variant="solid" size="sm" color="black" lineHeight="1.25rem" borderRadius="0.25rem" px="0.25rem">
                  Part 2 of 2
                </Badge>
                )}
              </Flex>
            </Box>

            <Flex
              border="0.063rem solid #CED8DD"
              p={2}
              width="100%"
              height="100%"
              overflowY="scroll"
              maxH="34.375rem"
              className="guidelines"
              gap="1rem"
              flexDir="column"
            >
              {isAgreementFetching ? (
                <Flex justify="center" align="center" height="12.5rem">
                  <Spinner size="lg" color="blue.500" />
                </Flex>
              ) : (
                <>
                  {parse(agreementData?.data?.content || '')}
                </>
              )}
            </Flex>
          </ModalBody>

          <ModalFooter
            alignContent="center"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            {guidelines_accepted ? (
              <Flex flexDir="column" margin="auto">
                <Button
                  onClick={() => onClose()}
                  variant="secondaryBlue600"
                  cursor="pointer"
                  padding="1rem !important"
                >
                  Close
                </Button>
              </Flex>
            ) : (
              <>
                {/* CheckBox */}
                <Flex flexDir="column" gap="0.5rem" alignItems="flex-start">
                  <Box>
                    <Checkbox
                      sx={{ fontSize: '0.875rem !important', fontWeight: '400', fontFamily: 'open sans' }}
                      isChecked={isGuidelinesAgreed}
                      onChange={() => setIsGuidelinesAgreed(!isGuidelinesAgreed)}
                    >
                      {AGREEMENT_CONSTANT.GUIDELINES_ACCEPT_CHECKBOX_TEXT}
                    </Checkbox>
                  </Box>
                  <Box fontFamily="open sans" justifyContent="flex-start" padding="0">
                    <Button
                      onClick={() => handleGuidelinesDeclined()}
                      style={{
                        ...subLinkStyle, textDecoration: 'underline', color: '#455763', fontSize: '0.875rem',
                      }}
                    >
                      {AGREEMENT_CONSTANT.GUIDELINES_DECLINE_LABEL_TEXT}
                    </Button>
                  </Box>
                </Flex>
                <Box
                  bg="blue.500"
                  color="white"
                  borderRadius="0.25rem"
                  padding="0.5rem 1rem"
                >
                  <Button color="white" onClick={() => updateGuidelinesStatus()} cursor="pointer">
                    Continue
                  </Button>
                </Box>
              </>
            )}

          </ModalFooter>
        </ModalContent>
      </Modal>

    </>
  );
};

export default GuidelinesModal;
