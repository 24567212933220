import { useHasValidToken } from '../slices/authentication.slice';
import { useActiveTenant } from './authentication.hook';
import { useUserLoading } from './user.hooks';

export function useHasRole(accepted) {
  const tenant = useActiveTenant();
  return tenant
    ?.licenses.some((license) => license.roles.some((role) => accepted.includes(role.role)));
}

export function useAuthorization() {
  const isPending = useUserLoading();
  const hasValidToken = useHasValidToken();
  const isNotAuthorized = !isPending && (!hasValidToken);

  return {
    isPending,
    isNotAuthorized,
    isAuthorized: !isPending && !isNotAuthorized,
  };
}
