import React from 'react';
import { TEEAuthContextProvider } from '@texas-education-exchange/edx-portal-shared';
import { useExchangeConfiguration } from '../hooks/configuration.hooks';
import baseConfig from '../baseConfig';

const SdkContext = ({ children }) => {
  const configuration = useExchangeConfiguration();

  console.log('configuration', configuration);
  baseConfig.setPropertyValue('apiBaseUrl', configuration.api.communityBaseUri);

  return (
    <TEEAuthContextProvider edxAppConfig={configuration}>
      {children}
    </TEEAuthContextProvider>
  );
};

export default SdkContext;
