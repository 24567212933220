const COLOR_VALUES = {
  gray: {
    100: '#E5E5E5',
    800: '#1E2D36',
  },
  sort: {
    ACTIVE: '#455763',
    NOT_ACTIVE: '#45576380',
  },
};

export default COLOR_VALUES;
