import React from 'react';
import {
  Button,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import {
  useAuthActions,
} from '@texas-education-exchange/edx-portal-shared';
import { setLoggedInUser } from '../slices/authSlice';
import { destroyToken } from '../slices/authentication.slice';

const NotAuthorized = () => {
  const { handleLogOut } = useAuthActions();

  const dispatch = useDispatch();
  const onLogout = async () => {
    dispatch(setLoggedInUser({}));
    dispatch(destroyToken());
    await handleLogOut();
  };

  return (
    <Flex
      flexDirection="column"
      maxWidth="780px"
      textAlign="left"
      justifyContent="center"
      gap={4}
      margin="auto"
      mt="15rem"
    >
      <Heading as="h1" color="blue.800" textAlign="center">
        We’re sorry, your account is not currently authorized to access The Exchange community.
      </Heading>
      <Text color="blue.800" textAlign="center">
        Contact your administrators for further assistance.
      </Text>
      <Button
        onClick={onLogout}
      >
        Log Out
      </Button>
    </Flex>
  );
};
export default NotAuthorized;
