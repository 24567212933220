/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  useParams, useNavigate, NavLink, useLocation,
} from 'react-router-dom';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  useTheme,
  Button,
  chakra,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  HStack,
  Box,
  Checkbox,
} from '@chakra-ui/react';
import Papa from 'papaparse';
import DownloadButton from '../ElementaryComponents/DownloadButton';
import SimplePagination from '../ElementaryComponents/SimplePagination';
import { useDownloadAnalyticsReportQuery } from '../../services/apis/reportsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import DeleteModal from '../Modals/DeleteModal';
import FilterArrowsIcon from '../Table/FilterArrows';
import REPORT_TYPES from './ReportType';
import COLOR_VALUES from '../../styles/ColorValues';
import { ASCENDING_ORDER, DESCENDING_ORDER } from '../Table/TableOptions';

const ReportDetails = () => {
  const { report_id } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const authorId = location.state?.author_id;
  const reportType = location.state?.type;
  const {
    startDate,
    endDate,
    reportType: reportSubType,
    createdAt,
    createdBy,
    subGroupName,
  } = location.state?.reportDetails || {};
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sorting, setSorting] = useState([]);
  const [sortConfig, setSortConfig] = useState(DESCENDING_ORDER);
  const reports = useSelector((state) => state.reports);
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;
  const report = reports.find((r) => r.id === parseInt(report_id, 10));
  const canDelete = loggedInUserId === authorId;
  const [isIncludeRemovedModerators, setIsIncludeRemovedModerators] = useState(true);
  const [isIncludeDeletedGroups, setIsIncludeDeletedGroups] = useState(true);
  const [totalRows, setTotalRows] = useState(0);

  const {
    data: presignedUrlData,
    isFetching: isReportFetching,
    isError: isReportError,
    error: reportError,
  } = useDownloadAnalyticsReportQuery(report_id);

  useEffect(() => {
    const presignedUrl = presignedUrlData?.presigned_url;
    if (presignedUrl) {
      fetch(presignedUrl)
        .then((response) => response.text())
        .then((csvContent) => {
          const parsedData = Papa.parse(csvContent, {
            header: true,
            dynamicTyping: true,
          }).data;
          setData(parsedData);
        })
        .catch((error) => console.error('Error fetching presignedUrl:', error));
    }
  }, [presignedUrlData]);

  const columnHelper = createColumnHelper();

  const columnKeys = useMemo(() => (data.length ? Object.keys(data[0]) : []), [data]);

  const ColumnKeyToTitle = (key) => {
    switch (key) {
      case 'Group Name (At Report Creation Date)':
        return 'Group Name';
      case 'Visibility (At Report Creation Date)':
        return 'Visibility';
      default:
        return key;
    }
  };

  const ColumnsToSkip = useMemo(() => {
    return [
      'Group ID',
      'Group Name (At Report End Date)',
      'Visibility (At Report End Date)',
      'Group Active (At Report End Date)',
      'Group Active (At Report Creation Date)',
    ];
  }, []);

  const NotSortableColumns = useMemo(() => { return ['Email', 'End Date', 'Group Name (At Report Creation Date)']; }, []);

  const columns = useMemo(() => {
    return columnKeys
      .filter((key) => !ColumnsToSkip.includes(key))
      .map((key) => {
        const isSortable = !NotSortableColumns.includes(key);
        return columnHelper.accessor(key, {
          header: ColumnKeyToTitle(key),
          enableSorting: isSortable,
          cell: (info) => info.getValue(),
        });
      });
  }, [columnKeys, columnHelper, ColumnsToSkip, NotSortableColumns]);

  const reportTypeToTitleMap = (providedReportType, providedSubGroupName = '') => {
    switch (providedReportType) {
      case 'all':
        return 'All Moderators';
      case 'lea':
        return providedSubGroupName;
      case 'moderator':
        return 'Moderator Report';
      default:
        return providedReportType;
    }
  };

  const filteredData = data;

  const GROUP_ACTIVE_AT_CREATION_DATE_LABEL = 'Group Active (At Report Creation Date)';

  const displayedData = useMemo(() => {
    let filtered = data;
    if (reportType === 'moderator' && !isIncludeRemovedModerators) {
      filtered = data.filter((d) => d.Status !== 'Past');
    }
    if (reportType === 'moderator' && !isIncludeDeletedGroups) {
      filtered = data.filter((d) => d[GROUP_ACTIVE_AT_CREATION_DATE_LABEL] !== 'No');
    }
    setTotalRows(filtered.length - 1);
    return filtered.sort((a, b) => {
      if (!sorting.length) return 0;
      const { id, desc } = sorting[0];
      return desc ? (a[id] < b[id] ? 1 : -1) : (a[id] > b[id] ? 1 : -1);
    }).slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  }, [
    data,
    sorting,
    currentPage,
    rowsPerPage,
    isIncludeRemovedModerators,
    reportType,
    isIncludeDeletedGroups,
  ]);

  const isGroupActivePresentInData = useMemo(() => {
    return Object.keys((data[0] ?? {})).includes(GROUP_ACTIVE_AT_CREATION_DATE_LABEL);
  }, [data]);

  const { getHeaderGroups, getRowModel } = useReactTable({
    data: displayedData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: { sorting },
    onSortingChange: setSorting,
  });

  if (isReportFetching) {
    return (
      <StatusAlert status="loading" alert_title="Fetching Report Data..." />
    );
  }
  if (isReportError) return <StatusAlert status="error" error={reportError} />;

  const handleBack = () => {
    if (reportType === REPORT_TYPES.INFRACTION) {
      navigate('/moderation-report', {
        state: { type: REPORT_TYPES.INFRACTION },
      });
    } else if (reportType === REPORT_TYPES.MODERATOR) {
      navigate('/moderation-report', {
        state: { type: REPORT_TYPES.MODERATOR },
      });
    } else {
      navigate('/analytics');
    }
  };

  const updateSorting = (header) => {
    const newSorting = [
      {
        id: header.id,
        desc: sortConfig !== ASCENDING_ORDER,
      },
    ];
    setSortConfig(
      sortConfig === ASCENDING_ORDER ? DESCENDING_ORDER : ASCENDING_ORDER,
    );
    setSorting(newSorting);
  };

  return (
    <Box mt={4} w="full">
      <NavLink
        to={reportType !== REPORT_TYPES.ANALYTIC ? '/moderation-report' : '/analytics'}
      >
        <Heading
          as="h1"
          color="blue.800"
          style={{
            ...theme.styles.global.h1,
            display: 'inline-block',
          }}
        >
          {reportType === REPORT_TYPES.MODERATOR
            ? 'Moderator Reports'
            : reportType === REPORT_TYPES.INFRACTION
              ? 'Infractions Reports'
              : 'Analytics'}
        </Heading>
      </NavLink>
      <HStack justifyContent="space-between" mb={2} mt={4} w="full">
        <Heading
          as="h2"
          color="blue.800"
          style={{
            ...theme.styles.global.h3,
          }}
        >
          {reportType !== REPORT_TYPES.ANALYTIC ? 'Report' : 'Your Activity Report'}
        </Heading>
        <Button
          size="lg"
          onClick={handleBack}
          variant="noOutline"
          colorScheme="blue"
          color="blue.500"
          sx={{ padding: 0 }}
        >
          Back to Your Reports
        </Button>
      </HStack>

      {/* Overview Section */}
      <Box marginBottom="2rem">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          boxShadow="rgba(0, 0, 0, 0.1) 0.063rem 0.25rem 0.5rem"
          borderRadius="0.5rem"
          borderWidth="0.063rem"
          padding="2rem"
          fontFamily="Open Sans"
        >
          <Box>
            {startDate}
            {' '}
            to
            {' '}
            {endDate}
          </Box>

          <Box>{reportTypeToTitleMap(reportSubType, subGroupName)}</Box>

          <Box fontWeight="400">{createdAt}</Box>

          <Box fontWeight="400">{createdBy}</Box>

          {canDelete && (
            <DeleteModal
              itemId={report_id}
              deleteType="report"
              isIcon
              customBtnStyle={{ color: theme.colors.red[600] }}
            />
          )}
        </Flex>
      </Box>

      <Flex
        style={{
          ...theme.styles.global.container,
          borderRadius: '8px',
        }}
        flexDirection="column"
        width="full"
        p="40px"
      >
        {/* Checkbox Section */}
        {reportType === 'moderator' ? (
          <Flex gap={4} mb={4}>
            <Checkbox
              isChecked={isIncludeRemovedModerators}
              onChange={() => setIsIncludeRemovedModerators(!isIncludeRemovedModerators)}
            >
              Include Removed Moderators
            </Checkbox>
            <Checkbox
              isDisabled={!isGroupActivePresentInData}
              isChecked={isIncludeDeletedGroups}
              onChange={() => setIsIncludeDeletedGroups(!isIncludeDeletedGroups)}
            >
              Include Deleted Groups
            </Checkbox>
          </Flex>
        ) : null}

        {/* New Table */}
        {filteredData.length > 0 ? (
          <Table
            style={{
              ...theme.styles.global.container,
              tableLayout: 'fixed',
              width: '100%',
              borderRadius: '0.125rem',
              boxShadow: 'none',
              fontFamily: 'Archivo Narrow',
            }}
            mb="40px"
          >
            <Thead
              borderBottom="0.125rem"
              borderBottomColor={`var(--The-Exchange-Color-Palette-Gray-100, ${COLOR_VALUES.gray[100]})}`}
            >
              {getHeaderGroups().map((headerGroup) => {
                return (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      const sortEntry = sorting.find((s) => s.id === header.id);
                      const isSorted = !!sortEntry;
                      return (
                        <Th
                          key={header.id}
                          cursor={
                            header.column.getCanSort() ? 'pointer' : 'default'
                          }
                          onClick={() => {
                            if (!header.column.getCanSort()) return;
                            updateSorting(header);
                          }}
                          onKeyUp={(e) => {
                            if (
                              e.key === 'Enter'
                              && header.column.getCanSort()
                            ) {
                              updateSorting(header);
                            }
                          }}
                          color="black"
                          fontFamily="Poppins"
                          fontSize="1rem"
                          fontStyle="normal"
                          fontWeight="700"
                          tabIndex={0}
                          lineHeight="1.25rem"
                          textTransform="none"
                          textAlign="left"
                          paddingLeft="1rem"
                          paddingY="0.5rem"
                        >
                          <Flex alignItems="center" justifyContent="left">
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                header.column.columnDef.header,
                                header.getContext(),
                              )}
                            {header.column.getCanSort() && (
                              <chakra.span
                                fontSize="0.75rem"
                                color="gray.500"
                                ml="0.5rem"
                                w="0.5rem"
                                height="0.875rem"
                                display="inline-block"
                              >
                                <FilterArrowsIcon
                                  width="0.5rem"
                                  height="0.875rem"
                                  sortDirection={
                                    isSorted ? sortConfig : undefined
                                  }
                                />
                              </chakra.span>
                            )}
                          </Flex>
                        </Th>
                      );
                    })}
                  </Tr>
                );
              })}
            </Thead>
            <Tbody>
              {getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          color={`var(--The-Exchange-Color-Palette-Gray-800, ${COLOR_VALUES.gray[800]})`}
                          fontStyle="normal"
                          fontWeight="400"
                          lineHeight="1rem"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                          className="table-cell"
                          width="6.25rem"
                          paddingLeft="1rem"
                          paddingY="1rem"
                          textAlign="left"
                          sx={{
                            '&:hover': {
                              whiteSpace: 'normal',
                              overflow: 'visible',
                              height: 'auto',
                              zIndex: '10',
                              bg: 'white',
                              boxShadow: 'md',
                              padding: '0.25rem',
                              border: '0.0625rem solid',
                              borderColor: 'gray.300',
                            },
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        ) : (
          <Box w="full" h="full" p="2rem" background="gray.50" mb="2">
            <Flex flexDir="column" alignItems="center" justifyContent="center" gap="0.25rem">
              <Box>0 Moderators Fit the Criteria for this Report</Box>
              <Box fontWeight="400">Select new criteria on the previous page and try again.</Box>
            </Flex>
          </Box>
        )}

        <SimplePagination
          totalRows={totalRows}
          rowsPerPage={rowsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRowsPerPage={setRowsPerPage}
        />
        <HStack justifyContent="flex-end" mt={6} gap={4}>
          {loggedInUserId === authorId && filteredData.length > 0 && (
            <DeleteModal
              deleteType="report"
              itemId={report_id}
              variant="solid"
              customBtnStyle={{ width: '6.75rem' }}
              size="md"
            />
          )}
          {reportType !== 'moderator' && filteredData.length > 0 && (
            <DownloadButton
              report_id={report_id}
              fileName={report?.file_object?.file_name}
              variant="outline"
              buttonLabel="Download Report"
              size="md"
            />
          )}
        </HStack>
      </Flex>
    </Box>
  );
};

export default ReportDetails;
