import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import baseConfig from '../baseConfig';

const baseQuery = async (args, WebApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({

    baseUrl: baseConfig.getPropertyByName('apiBaseUrl'),
    prepareHeaders: (headers, { getState }) => {
      const state = getState();
      const currentLoggedInUser = state?.auth?.loggedInUser;
      const token = state.authentication?.token;

      console.log('currentLoggedInUser', currentLoggedInUser);

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);

        if (currentLoggedInUser?.profile?.sub) {
          headers.set('req-user-id', currentLoggedInUser.profile.sub);
        }
      } else {
        console.warn('No valid token found in authentication slice, skipping Authorization header');
      }

      return headers;
    },
  });

  return rawBaseQuery(args, WebApi, extraOptions);
};

const apiService = createApi({
  reducerPath: 'api',
  baseQuery,
  endpoints: () => ({}),
});

export default apiService;
