import React from 'react';
import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useTheme,
} from '@chakra-ui/react';
import moment from 'moment';
import ManageModeratorsModal from '../Modals/ManageModeratorsModal';
import { useSearchUsersQuery } from '../../services/apis/searchAPI';
import { joinTruthyStrings } from '../../utils/helpers';
import ProfileCardPopOver from './ProfileCardPopOver';
import UserAvatar from '../ElementaryComponents/UserAvatar';

const GroupDetailsPopOver = ({ triggerComp, groupData, user_banned }) => {
  const theme = useTheme();
  const { created_at, author_body = {}, _id } = groupData.data;
  const { data: moderatorsData } = useSearchUsersQuery({
    page_number: 1,
    page_size: 100,
    sort_order: 'desc',
    group_id: _id,
    group_user_type: 'moderator',
  });

  const can_manage_moderators = groupData.permissions?.group?.can_manage_moderators;
  const formattedCreatedDate = moment(created_at).format('MM/DD/YY');
  const { first_name = '', last_name = '' } = author_body;

  let authorName = joinTruthyStrings({
    strings: [first_name, last_name],
    connectingString: ' ',
  });
  if (!authorName) authorName = author_body?._id;

  return (
    <Popover>
      <PopoverTrigger>
        <Button width="44px" variant="ghost" aria-label="Group Users">
          {triggerComp}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{
          position: 'absolute',
          right: '8px',
          width: 'max-content',
          minWidth: '320px',
          maxWidth: '500px',
        }}
      >
        <PopoverBody
          style={{ ...theme.styles.components.popOverStyle, width: '100%' }}
          padding="1.25rem 1rem"
        >
          <Flex
            borderBottom={`1px solid ${theme.colors.gray['300']}`}
            flexDir="column"
            pb={2}
            mb={2}
          >
            <Text fontWeight="600" mb="0.5rem">
              Created On
              {' '}
              {formattedCreatedDate}
              {' '}
              by
            </Text>
            <Flex key={_id} alignItems="center">
              <ProfileCardPopOver
                memberProfile={author_body}
                userAvatar={(
                  <UserAvatar
                    name={authorName}
                    user_profile_pic=""
                    avatarSize="XS"
                  />
                )}
              />
              <Text sx={{ ...theme.styles.global.h4 }} key={_id} ml={2}>
                {authorName}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDir="column">
            <Text fontWeight="600" mb="0.5rem">
              Moderators
            </Text>
            <Flex flexDirection="column" gap={2}>
              {moderatorsData?.data?.map((moderator) => {
                const moderatorName = joinTruthyStrings({
                  strings: [moderator.first_name, moderator.last_name],
                  connectingString: ' ',
                });
                return (
                  <Flex key={moderator?._id} alignItems="center">
                    <ProfileCardPopOver
                      memberProfile={moderator}
                      userAvatar={(
                        <UserAvatar
                          name={moderatorName}
                          user_profile_pic=""
                          avatarSize="XS"
                        />
                      )}
                    />
                    <Text sx={{ ...theme.styles.global.h4 }} key={_id} ml={2}>
                      {moderatorName}
                    </Text>
                  </Flex>
                );
              })}
            </Flex>
          </Flex>
          {can_manage_moderators && !user_banned && (
            <ManageModeratorsModal
              moderatorsData={moderatorsData}
              group_id={_id}
              isGroupPrivate={groupData.data.visibility !== 'public'}
            />
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default GroupDetailsPopOver;
