import {
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { createRemoteJWKSet, decodeJwt, jwtVerify } from 'jose';

import { useMemo } from 'react';
import { addDays, differenceInMilliseconds } from 'date-fns';
import { useAppSelector } from '../hooks/store.hook';
import { configurationAPI } from '../services/apis/configurationAPI';

export const initialState = {
  token: undefined,
};

const DEFAULT_IDLE_OFFSET_DAYS = 1;
const now = new Date();
const adjusted = addDays(now, DEFAULT_IDLE_OFFSET_DAYS);
const timeout = differenceInMilliseconds(adjusted, now);

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setToken: (state, action) => {
      console.log('🔄 Redux Reducer - setToken triggered:', action.payload);
      state.token = action.payload;
    },
    destroyToken: (state) => {
      console.log('❌ Redux Reducer - destroyToken triggered');
      state.token = undefined;
    },
  },
});

export const { setToken, destroyToken } = authenticationSlice.actions;

export const validateToken = createAsyncThunk(
  'authentication/validateToken',
  async ({ token, configurationUri }, { dispatch }) => {
    console.log('🚀 Dispatching validateToken for token:', token);

    const { jwks_uri } = await dispatch(
      configurationAPI.endpoints.getOpenIdConfiguration.initiate(configurationUri),
    ).unwrap();

    console.log('🔑 JWKS URI:', jwks_uri);

    const { payload } = await jwtVerify(
      token,
      createRemoteJWKSet(new URL(jwks_uri), { cacheMaxAge: timeout }),
    );

    console.log('✅ Token verification successful. Payload:', payload);

    dispatch(setToken(token));
    console.log('🛠 Token set in Redux:', token);

    return token;
  },
);

export const selectAuthenticationState = (state) => state.authentication;

export function useAuthState() {
  console.log('state', useAppSelector(selectAuthenticationState));
  return useAppSelector(selectAuthenticationState) || { token: undefined };
}

export function useAuthToken() {
  const authState = useAuthState();
  console.log('📌 useAuthToken - Current Redux state:', authState);
  return authState.token;
}

export function useHasValidToken() {
  const { token } = useAuthState();
  return !!token;
}

export function useTokenPayload() {
  const token = useAuthToken();
  return useMemo(() => {
    if (token) {
      try {
        return decodeJwt(token);
      } catch {
        return null;
      }
    }
    return null;
  }, [token]);
}

export function useTokenRoles() {
  const payload = useTokenPayload();
  return payload?.role;
}

export function useTokenUserId() {
  const payload = useTokenPayload();
  return payload?.tenantid;
}

export default authenticationSlice.reducer;
