import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRedirectUrl } from '../hooks/redirect.hook';

const MINIMUM_LENGTH = 0;
const CallbackRouter = () => {
  const navigate = useNavigate();

  const [savedUrl, setSavedUrl] = useRedirectUrl();

  const [redirect] = useState(savedUrl);

  useEffect(() => {
    console.log('Redirect URL:', navigate);
  }, [navigate]);

  useEffect(() => {
    navigate(
      redirect && redirect.length > MINIMUM_LENGTH ? redirect : '../dashboard',
    );
  }, [redirect, navigate]);

  useEffect(() => {
    setSavedUrl('');
  }, [savedUrl, setSavedUrl]);

  return <></>;
};

export default CallbackRouter;
